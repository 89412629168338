import { get } from './axios';

const CALENDAR_FESTIVALS_ENDPOINT = '/dashboard/calendar/festivals/';

const CALENDAR_CAMPAIGNS_ENDPOINT = '/dashboard/calendar/campaigns';

const CALENDAR_CAMPAIGN_CONTENT_GENERATION_ENDPOINT = (
  id: string,
  channel: string = 'webpush',
) => `/dashboard/calendar/festivals/${id}/campaign-content/${channel}`;

export const fetchCalendarFestivalsAPI = () => get(CALENDAR_FESTIVALS_ENDPOINT);

export const fetchCalendarCampaignsAPI = () => get(CALENDAR_CAMPAIGNS_ENDPOINT);

export const fetchCalendarCampaignContentAPI = (id: string, channel?: string) =>
  get(CALENDAR_CAMPAIGN_CONTENT_GENERATION_ENDPOINT(id, channel));
