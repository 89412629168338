enum steps {
  INITIAL_MODAL = 1,
  CAMPAIGN_CREATION_FIRST_STEP,
  NOTIFICATION_PREVIEW,
  ADD_LOGO,
  TITLE,
  DESCRIPTION,
  PRIMARY_LINK,
  HERO_IMAGE,
  ADD_BUTTONS,
  ADD_BUTTONS_EXPANDED,
  LIVE_PREVIEW,
  GO_TO_SUMMARY,
  CREATE_TEST_CAMPAIGN,
  COMPLETED,
}

export default steps;
