import { get, post } from '../axios';

// endpoints
const CONFIG_ENDPOINT = `/dashboard/config/abandoned-cart/notification/`;

// query helpers
export const fetchACRConfigAPI = () => get(CONFIG_ENDPOINT);

export const setACRConfigAPI = (data: {
  enabled: boolean;
  notification_configs: Array<AnyObject>;
}) => post(CONFIG_ENDPOINT, data);
