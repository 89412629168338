import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { Fragment, useMemo } from 'react';
import { useGlobalState } from 'src/hooks/use-global-state';
import { TrialModal } from 'src/modules/pricing/components/TrialModal';

const Modals = ({ isAppDisabled }) => {
  const router = useRouter();
  const {
    state: { isAuthenticating },
  } = useGlobalState('user');

  const ConsumptionWarningPopup = useMemo(() => {
    if (isAuthenticating) return Fragment;

    return dynamic(() => import('src/components/ConsumptionWarningPopup'), {
      ssr: false,
    });
  }, [isAuthenticating]);

  if (
    isAppDisabled ||
    /(login$|onboarding|stripe|embedded-auth|\/subscribe$)/.test(
      router.pathname,
    )
  )
    return null;

  return (
    <>
      <TrialModal />
      <ConsumptionWarningPopup />
    </>
  );
};

export const GlobalModals = Modals;
