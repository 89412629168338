import { FullStory, init } from '@fullstory/browser';
import * as Sentry from '@sentry/browser';
import { fullStoryIntegration } from '@sentry/fullstory';
import { Component } from 'react';
import { ignoredErrorsRegex } from 'src/components/ErrorBoundary/ignoredErrors';
import { isClientSide } from 'src/lib/utils';
import { logErrorToSentry } from './debug-utils';

class LoggingErrorBoundary extends Component<{ children: React.ReactNode }> {
  componentDidMount() {
    init({
      orgId: 'P2M9E',
      recordOnlyThisIFrame: isClientSide() && window !== window.parent,
    });

    Sentry.init({
      dsn:
        process.env.NODE_ENV === 'production'
          ? 'https://aea75c3e7677488fb4ee1cfa49993793@sentry.io/1891624'
          : null,
      integrations: [fullStoryIntegration('pushowl', { client: FullStory })],
      ignoreErrors: ignoredErrorsRegex,
    });
  }
  componentDidCatch(error, errorInfo) {
    logErrorToSentry({ error, extras: errorInfo });
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

export default LoggingErrorBoundary;
