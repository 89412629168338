import { get, post } from '../axios';

// endpoints
const WIDGET_ENDPOINT = `/dashboard/config/back-in-stock/widget/`;
const CONFIG_ENDPOINT = `/dashboard/config/back-in-stock/notification/`;
const REPORT_ENDPOINT = `/dashboard/back-in-stock/report/`;

// query helpers
export const fetchBISWidgetAPI = () => get(WIDGET_ENDPOINT);

export const setBISWidgetAPI = (data: {
  enabled: boolean;
  metadata: AnyObject;
}) => post(WIDGET_ENDPOINT, data);

export const fetchBISConfigAPI = () => get(CONFIG_ENDPOINT);

export const setBISConfigAPI = (data: {
  enabled: boolean;
  metadata: AnyObject;
}) => post(CONFIG_ENDPOINT, data);

export const fetchBISReportAPI = () => get(REPORT_ENDPOINT);
