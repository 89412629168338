import { AutomationConfig } from 'src/modules/automation/models/types';
import { get, post } from '../axios';

// endpoints
const CONFIG_ENDPOINT = `/dashboard/config/welcome-notification/notification/`;
const REPORT_ENDPOINT = `/dashboard/welcome-notification/report/`;

// query helpers
export const fetchWelcomeConfigAPI = () => get(CONFIG_ENDPOINT);

export const setWelcomeConfigAPI = (data: {
  enabled: boolean;
  metadata?: AutomationConfig['metadata'];
  notification_configs?: Array<AutomationConfig>;
}) => post(CONFIG_ENDPOINT, data);

export const fetchWelcomeReportAPI = () => get(REPORT_ENDPOINT);
