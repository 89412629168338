import { createModel } from '@rematch/core';
import { isSmallScreen } from 'src/lib/utils';
import { RootModel } from '.';

interface CommonState {
  isSidebarOpen: boolean;
  isSmallScreen: boolean;
}

const initialState = (): CommonState => ({
  isSidebarOpen: false,
  isSmallScreen: isSmallScreen(),
});

const common = createModel<RootModel>()({
  state: initialState(),

  reducers: {
    setIsSmallScreen(state: CommonState, isSmallScreen: boolean): CommonState {
      return {
        ...state,
        isSmallScreen,
      };
    },

    toggleSidebar(state: CommonState): CommonState {
      return {
        ...state,
        isSidebarOpen: !state.isSidebarOpen,
      };
    },
  },
});

export default common;
