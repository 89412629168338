import colors from './colors';
import { fontSizes } from './typography';
import space from './space';
import { radii, zIndex, responsiveScreenBreakpoint } from './misc';

const tokens: Tokens = {
  colors,
  fontSizes,
  space,
  radii,
  zIndex,
  responsiveScreenBreakpoint,
};

export default tokens;
