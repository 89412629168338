import { createModel } from '@rematch/core';
import { fetchActivityFeedAPI } from 'src/lib/api/activityFeed';

export enum EventType {
  CHANGED = 'changed',
  ADDED = 'added',
  REMOVED = 'removed',
}

export interface Change {
  identifier: string;
  identifier_human: string | null;
  new_value: any;
  old_value: any;
  event_type: EventType;
}

export interface FeedItem {
  timestamp: string;
  config_name: string;
  changes: Array<Change>;
}

export interface ActivityFeedState {
  isLoading: boolean;
  error: string | null;
  startTime: Date | null;
  endTime: Date | null;
  timeFilter: {
    type: string;
    value: number | AnyObject;
  };
  hasMore: boolean;
  count: number;
  feed: Array<FeedItem>;
  page: number;
}

const initialState = (): ActivityFeedState => ({
  isLoading: true,
  hasMore: false,
  error: null,
  startTime: null,
  endTime: null,
  count: 0,
  page: 1,
  feed: [],
  timeFilter: {
    type: 'fixed',
    value: 189216000,
  },
});

const activityFeed = createModel()({
  state: initialState(),
  effects: {
    async fetchActivityFeed(
      payload: Pick<
        ActivityFeedState,
        'page' | 'startTime' | 'endTime' | 'timeFilter'
      >,
    ) {
      this.setState(payload);
      this.setIsLoading({ isLoading: true });
      const { data, error } = await fetchActivityFeedAPI({
        ...payload,
        granularity: 'smallest',
        names: 'all',
        records_per_page: 25,
      });

      if (error) {
        this.handleErrorResponse({ error });
      } else {
        this.storeActivityFeed(data);
      }
    },
  },
  reducers: {
    setState(state: ActivityFeedState, payload) {
      return {
        ...state,
        ...payload,
        feed: payload.page === 1 ? [] : state.feed,
      };
    },
    setIsLoading(state: ActivityFeedState, payload) {
      return {
        ...state,
        isLoading: payload.isLoading,
      };
    },
    storeActivityFeed(state: ActivityFeedState, payload) {
      return {
        ...state,
        isLoading: false,
        error: null,
        count: payload.count,
        feed: [...state.feed, ...payload.results],
        hasMore: payload.next !== null,
      };
    },
    handleErrorResponse(state: ActivityFeedState, payload) {
      return {
        ...state,
        isLoading: false,
        error: payload.error.message || 'Unable to load activity feed',
      };
    },
  },
});

export default activityFeed;
