import { createModel } from '@rematch/core';
import { fetchNotificationTemplatesAPI } from 'src/lib/api/notification';
import { v1 as uuidv1 } from 'uuid';

export type HeroImage = {
  desktop?: null | string;
  mobile?: null | string;
  macos?: null | string;
};

export type Button = {
  id: string;
  title: string;
  link: string;
};

export interface CampaignTemplates {
  id: string;
  title: string;
  description: string;
  categories: string[];
  icon_image: string;
  mac_image: string;
  mobile_image: string;
  window_image: string;
  status: string;
  image: string;
  redirected_url: string;
  created_at: string;
  modified_at: string;
}

export interface NCState {
  campaignTemplates: {
    templates: CampaignTemplates[];
    isFetching: boolean;
    errorMessage: null | string;
    lastAppliedTemplateId: null | string;
  };
  title: string;
  message: string;
  primaryLink: string;
  discountCode: string | null;
  heroImage: HeroImage;
  buttons: Array<Button>;
  icon: string;
  isModified: boolean;

  isImageEditorOpen: boolean;
  isPreviewDrawerOpen: boolean;
  heroImageBeingEdited: 'desktop' | 'mobile' | 'macos' | null;
  errors: AnyObject;
}

const initialState = (): NCState => ({
  campaignTemplates: {
    templates: [],
    isFetching: false,
    errorMessage: null,
    lastAppliedTemplateId: null,
  },
  title: '',
  message: '',
  primaryLink: '',
  discountCode: null,
  heroImage: {
    desktop: null,
    mobile: null,
    macos: null,
  },
  buttons: [],
  icon: null,
  isModified: false,
  isImageEditorOpen: false,
  heroImageBeingEdited: null,
  isPreviewDrawerOpen: false,
  errors: {
    title: null,
    primaryLink: null,
    discountCode: null,
  },
});

const notificationCreator = createModel()({
  state: initialState(),

  reducers: {
    setCampaignTemplates(state: NCState, payload: AnyObject): NCState {
      return {
        ...state,
        campaignTemplates: { ...state.campaignTemplates, ...payload },
      };
    },

    shut() {
      return initialState();
    },

    loadState(state: NCState, payload: AnyObject): NCState {
      return {
        // ...initialState(),
        ...state,
        ...payload,
      };
    },

    // for (title, message, primaryLink, errors, and logo)
    setProp(state: NCState, payload: { prop: string; value: any }): NCState {
      return {
        ...state,
        // Remove title, primary link and discount code errors if the user starts typing in these fields
        [payload.prop]: payload.value,
        isModified: true,
      };
    },

    setErrors(state: NCState, payload: AnyObject): NCState {
      const { merge = true, errors } = payload;
      const newErrors = merge ? { ...state.errors, ...errors } : errors;
      return {
        ...state,
        errors: newErrors,
      };
    },

    setHeroImage(state: NCState, payload: HeroImage): NCState {
      // Explicitly checking for `desktop` and `mobile` to be as keys
      // in the payload, they can be `null` too.
      // Don't replace with payload.desktop || state.heroImage.desktop
      return {
        ...state,
        heroImage: {
          desktop:
            'desktop' in payload ? payload.desktop : state.heroImage.desktop,
          mobile: 'mobile' in payload ? payload.mobile : state.heroImage.mobile,
          macos: 'macos' in payload ? payload.macos : state.heroImage.macos,
        },
        isModified: true,
      };
    },

    addButton(state: NCState): NCState {
      if (state.buttons.length === 2) {
        return state;
      }

      return {
        ...state,
        buttons: [
          ...state.buttons,
          {
            id: uuidv1(),
            title: '',
            link: '',
          },
        ],
        isModified: true,
      };
    },

    updateButton(state: NCState, payload: Button) {
      return {
        ...state,
        buttons: state.buttons.map((button: Button) =>
          button.id === payload.id ? payload : button,
        ),
        isModified: true,
      };
    },

    removeButton(state: NCState, payload: number) {
      return {
        ...state,
        buttons: state.buttons.filter((button, i) => i !== payload),
        isModified: true,
      };
    },

    toggleHeroImageEditor(
      state: NCState,
      payload: 'desktop' | 'mobile' | 'macos',
    ): NCState {
      return {
        ...state,
        isImageEditorOpen: !state.isImageEditorOpen,
        heroImageBeingEdited: payload,
      };
    },

    togglePreviewWrapper(state: NCState): NCState {
      return {
        ...state,
        isPreviewDrawerOpen: !state.isPreviewDrawerOpen,
      };
    },
  },

  effects: {
    async fetchTemplates() {
      this.setCampaignTemplates({
        isFetching: true,
      });

      const {
        data: { data: templates = [], error_message: errorMessage = null } = {},
      } = await fetchNotificationTemplatesAPI();

      this.setCampaignTemplates({
        isFetching: false,
        errorMessage,
        templates,
      });
    },
  },
});

export default notificationCreator;
