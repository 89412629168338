import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import Styled from './styled';

const SessionValidation = () => {
  const { t } = useTranslation('common');

  return (
    <Styled.Wrapper>
      <Styled.Message>
        {t(
          'This tab is unusable because you logged into some other store in another\n      tab.',
        )}
        <a href='/'>{t('Click here to refresh')}</a>
        {t('.')}
      </Styled.Message>
    </Styled.Wrapper>
  );
};

export default SessionValidation;
