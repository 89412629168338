import { get, post } from '../axios';

// endpoints
const CONFIG_ENDPOINT = `/dashboard/config/price-drop/notification/`;
const WIDGET_ENDPOINT = `/dashboard/config/price-drop/widget/`;
const REPORT_ENDPOINT = `/dashboard/price-drop/report/`;

// query helpers
export const fetchPriceDropWidgetAPI = () => get(WIDGET_ENDPOINT);

export const setPriceDropWidgetAPI = (data: {
  enabled: boolean;
  metadata: AnyObject;
}) => post(WIDGET_ENDPOINT, data);

export const fetchPriceDropConfigAPI = () => get(CONFIG_ENDPOINT);

export const setPriceDropConfigAPI = (data: {
  enabled: boolean;
  metadata: AnyObject;
}) => post(CONFIG_ENDPOINT, data);

export const fetchPriceDropReportAPI = () => get(REPORT_ENDPOINT);
