interface Props {
  style?: any;
  size?: number;
}

export const Win8 = ({ style, size = 20 }: Props) => (
  <svg width={size} height={size} viewBox='0 0 20 20' fill='none' style={style}>
    <path
      d='M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z'
      fill='#2995CC'
    />
    <path
      d='M9.0625 9.62956C9.0625 8.10628 9.0625 6.58316 9.0625 5.05988C7.5 5.30691 6.22906 5.553 4.6875 5.79988C4.6875 7.35378 4.6875 8.13081 4.6875 9.68488C6.22906 9.66691 7.5 9.64769 9.0625 9.62956Z'
      fill='#F5F5F5'
    />
    <path
      d='M9.6875 9.62048C11.25 9.60173 13.1459 9.58392 14.6875 9.56501C14.6875 7.77673 14.6875 5.98829 14.6875 4.20001C13.1459 4.44704 11.25 4.69298 9.6875 4.94017C9.6875 6.50032 9.6875 8.06032 9.6875 9.62048Z'
      fill='#F5F5F5'
    />
    <path
      d='M9.75 10.3797C9.74938 11.9397 9.75 13.4999 9.75 15.06C11.2914 15.307 13.1456 15.5531 14.6875 15.8C14.6875 14.0117 14.6875 12.2231 14.6875 10.435C13.1459 10.4167 11.2919 10.3981 9.75 10.3797Z'
      fill='#F5F5F5'
    />
    <path
      d='M9.0625 10.3705C7.5 10.3519 6.22906 10.3336 4.6875 10.315C4.6875 11.8691 4.6875 12.6459 4.6875 14.2C6.22906 14.4472 7.5 14.693 9.0625 14.94C9.0625 13.4169 9.0625 11.8938 9.0625 10.3705Z'
      fill='#F5F5F5'
    />
  </svg>
);
