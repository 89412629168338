export const CheckIcon = ({ color = '#29845A' }) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none'>
    <g clipPath='url(#a)'>
      <rect width='12' height='12' fill={color} rx='6' />
      <path
        fill='#fff'
        fillRule='evenodd'
        d='M8.549 3.696 4.969 7.15l-.95-1.015a.519.519 0 0 0-.65-.035.458.458 0 0 0-.13.605l1.125 1.83c.11.17.3.275.515.275.205 0 .4-.105.51-.275.18-.235 3.615-4.33 3.615-4.33.45-.46-.095-.865-.455-.515v.005Z'
        clipRule='evenodd'
      />
    </g>
    <defs>
      <clipPath id='a'>
        <rect width='12' height='12' fill='#fff' rx='6' />
      </clipPath>
    </defs>
  </svg>
);
