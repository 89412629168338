import cloneDeep from 'lodash.clonedeep';

function dateToUnixTimestamp(date) {
  if (!(date instanceof Date)) return date;
  date.setHours(0, 0, 0, 0);
  return Math.floor(date.getTime() / 1000);
}
export const segmentDefinitionRequestTransform = segmentDefinition => {
  const newSegmentDefinition = cloneDeep(segmentDefinition);

  // transform the request data. Need to convert all datestrings to unix timestamps
  (newSegmentDefinition || []).forEach(segmentGroup => {
    segmentGroup.forEach(condition => {
      if (
        condition.timeDuration &&
        (condition.timeDuration.value instanceof Date ||
          Array.isArray(condition.timeDuration.value))
      ) {
        // has double dates
        if (Array.isArray(condition.timeDuration.value)) {
          /* eslint-disable no-param-reassign */
          condition.timeDuration.value[0] = dateToUnixTimestamp(
            condition.timeDuration.value[0],
          );
          condition.timeDuration.value[1] = dateToUnixTimestamp(
            condition.timeDuration.value[1],
          );
        } else {
          /* eslint-disable no-param-reassign */
          condition.timeDuration.value = dateToUnixTimestamp(
            condition.timeDuration.value,
          );
        }
      }
    });
  });

  return newSegmentDefinition;
};

export const segmentDefinitionResponseTransform = segmentDefinition => {
  const newSegmentDefinition = cloneDeep(segmentDefinition);

  // transform the request data. Need to convert all unix timestamps to date objs
  if (newSegmentDefinition && newSegmentDefinition.length > 0) {
    newSegmentDefinition.forEach(segmentGroup => {
      segmentGroup.forEach(condition => {
        // 118764657 is randomly selected epoch timestamp from 1970
        if (condition.timeDuration) {
          if (condition.timeDuration.value > 118764657) {
            /* eslint-disable no-param-reassign */
            condition.timeDuration.value = new Date(
              condition.timeDuration.value * 1000,
            );
          } else if (Array.isArray(condition.timeDuration.value)) {
            condition.timeDuration.value[0] = new Date(
              condition.timeDuration.value[0] * 1000,
            );
            condition.timeDuration.value[1] = new Date(
              condition.timeDuration.value[1] * 1000,
            );
          }
        }
      });
    });
  }
  // doing this because newSegmentDefinition becomes an object
  // if it's empty however we assume it's an array even if it's empty in the codebase
  if (newSegmentDefinition?.length) {
    return newSegmentDefinition;
  }
  return [];
};
