import { get, post } from '../axios';

// endpoints
const CONFIG_ENDPOINT = `/dashboard/config/shipping/fulfillment-complete/notification/`;
const REPORT_ENDPOINT = `/dashboard/fulfillment-complete/report/`;

// query helpers
export const fetchShippingConfigAPI = () => get(CONFIG_ENDPOINT);

export const setShippingConfigAPI = (data: {
  enabled: boolean;
  metadata: AnyObject;
}) => post(CONFIG_ENDPOINT, data);

export const fetchShippingReportAPI = () => get(REPORT_ENDPOINT);
