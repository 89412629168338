import { CampaignType } from 'src/modules/campaigns/models';
import { transformAttributionSettingsRequest as getAttributionWindow } from 'src/modules/settings/models';
import { EmailOnboardingStatus } from 'src/modules/home/models/types';
import { ReportTypes } from 'src/components/ReportSaver/models';
import { get, post } from './axios';
import { getTimeWindow } from './reports/helpers';

export const getIntegratedDashboardCardSummary = (
  campaignStates: CampaignType[],
  { timeFilter: { value }, segmentIds, campaignTypes },
  attributionSettings,
) => {
  const attribution_window = getAttributionWindow(attributionSettings);

  const time_window = getTimeWindow(value);

  const segment_ids = segmentIds.map(id => +id);

  const reportData = window.btoa(
    JSON.stringify(
      campaignStates.map(state => ({
        name: ReportTypes.TOTAL_CAMPAIGN_PERF,
        params: {
          time_window,
          attribution_window,
          segment_ids,
          campaign_types: campaignTypes,
          campaign_state: state,
        },
      })),
    ),
  );

  return get(`/dashboard/home/summary/?report_data=${reportData}`, null, {
    version: 'v2',
  });
};

export const getWebpushOnboardingStatus = () =>
  get('/dashboard/webpush/onboarding/status/');

export const getEmailOnboardingCardStatus = () =>
  get('/dashboard/email/onboarding/banner/status/');

export const toggleEmailOnboardingCardStatus = (
  status: EmailOnboardingStatus,
) => post('/dashboard/email/onboarding/banner/status/', { status });

export const getEmailOnboardingStatus = () =>
  get('/dashboard/email/onboarding/status/');
