import { BillingFrequency } from './constants';
import { Plan } from './models/types';

export function isPlanUpgrade(oldPlan, newPlan) {
  // FIXME: Oh God! Please forgive me for this sin.
  // oldPlan has `impressions_limit` but newPLan has `impressions`.
  // Its bcoz backend sends snakecase keys :)

  // we consider an upgrade
  // if impressions are more
  if (newPlan.impressions > oldPlan?.impressions_limit) return true;

  // or if impressions are same, but we shifting from monthly to annual
  if (
    newPlan.impressions === oldPlan?.impressions_limit &&
    oldPlan?.plan_charge_type === BillingFrequency.Monthly &&
    newPlan.planChargeType === BillingFrequency.Annual
  ) {
    return true;
  }

  return false;
}

export const getDiscountPrice = ({ price, coupon }) => {
  const { amountOff, percentageOff, discountType } = coupon.rule;

  if (discountType === 'percentage') {
    return price - (price * percentageOff) / 100;
  }

  if (discountType === 'amount') {
    return price - amountOff;
  }

  return price;
};

export const getBillingDiscountFrequency = ({
  businessBillingFrequency,
  enterpriseBillingFrequency,
  enterpriseIndex,
  businessIndex,
  couponBillingFrequency,
}) => {
  if (couponBillingFrequency !== BillingFrequency.ALL)
    return couponBillingFrequency;

  // If the billing frequency of the plans that the coupon applies to is different
  if (businessBillingFrequency !== enterpriseBillingFrequency) {
    if (enterpriseIndex) {
      return enterpriseBillingFrequency;
    }

    if (businessIndex) {
      return businessBillingFrequency;
    }
  }

  return businessBillingFrequency;
};

export const findCouponPlanIndex = ({
  monthlyPlans,
  annualPlans,
  couponCodeSkus,
  currentBillingFrequency,
  couponBillingFrequency,
}) => {
  const annualIndex = (annualPlans || []).findIndex((plan: Plan) =>
    couponCodeSkus.includes(plan.sku),
  );
  const monthlyIndex = (monthlyPlans || []).findIndex((plan: Plan) =>
    couponCodeSkus.includes(plan.sku),
  );

  if (
    annualIndex !== -1 &&
    couponBillingFrequency !== BillingFrequency.Monthly
  ) {
    return [annualIndex, BillingFrequency.Annual];
  }

  if (
    monthlyIndex !== -1 &&
    couponBillingFrequency !== BillingFrequency.Annual
  ) {
    return [monthlyIndex, BillingFrequency.Monthly];
  }

  return [null, currentBillingFrequency];
};
