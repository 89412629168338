import { isBefore, startOfDay } from 'date-fns';

export const formatCampaigns = campaigns => {
  if (Object.keys(campaigns).length === 0) {
    return [];
  }

  return campaigns.map(campaign => ({
    campaignDetails: { ...campaign },
    title: campaign.title,
    start: campaign.scheduled_time
      ? new Date(campaign.scheduled_time * 1000).toISOString()
      : campaign.dispatched_time,
    end: campaign.scheduled_time
      ? new Date(campaign.scheduled_time * 1000).toISOString()
      : campaign.dispatched_time,
    allDay: false,
    id: campaign.id,
    isSent: campaign.is_sent,
    isCampaign: true,
  }));
};

export const formatFestivals = festivals => {
  return festivals.map(festival => ({
    ...festival,
    allDay: true,
    isFestival: true,
  }));
};

// This function retrieves the user's timezone using the Intl API
export function getUserTimezone(): string {
  if (typeof Intl !== 'undefined') {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
  return 'UTC'; // Default to UTC if unable to determine the timezone
}

// This function takes a date and converts it to the user's local timezone
export function convertToUserTimezone(date: Date): Date {
  const userTimezone = getUserTimezone();
  return new Date(date.toLocaleString('en-US', { timeZone: userTimezone }));
}

// Utility function to check if a date has passed
export const isDatePassed = date => {
  return isBefore(startOfDay(new Date(date)), startOfDay(new Date()));
};
