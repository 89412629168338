import { createModel } from '@rematch/core';
import { startOfDay, subDays } from 'date-fns';
import {
  TimeFilter as DateRangeTimeFilter,
  DaysInSeconds,
} from 'src/components/HistoricalDaterangePicker/types';
import {
  OldTimeFilter,
  ReportsGranularity,
} from 'src/components/ReportSaver/models';
import {
  fetchSummaryAPI,
  fetchThemeAppExtensionStatusAPI,
  toggleNewDashboardAPI,
  toggleOnboardingStatusForMerchantAPI,
} from 'src/lib/api/home';
import {
  fetchSubscriberGrowthReportAPI,
  fetchTotalAttributedRevenueAPI,
} from 'src/lib/api/reports';
import { WebpushOnboardingStatus } from 'src/modules/home/models/types';
import { RootModel } from 'src/store/models';

// TODO:aftermigration - use the new time filter type only
type TimeFilter = [Date, Date, DateRangeTimeFilter | OldTimeFilter];

interface HomepageReport {
  isFetching: boolean;
  isThemeAppExtensionEnabled: boolean;
  isFetchingThemeAppStatus: boolean;
  summary: {
    attributed_revenue: {
      total: number;
    };
    campaigns: { total: number; sent: number; scheduled: number };
    last_campaign: any;
    subscribers: { total: number };
  };
  reports: {
    isFetching: boolean;
    filters: {
      timeFilter: TimeFilter;
      granularity: ReportsGranularity;
    };
    subscriberGrowthBreakdown: { date: string; count: number }[];
    totalSubsGained: number;
    subsCountryBreakdown: { country: string; count: number }[];
    totalAttributedRevenue: number;
  };
}

const initialState = (): HomepageReport => ({
  isFetching: true,
  isThemeAppExtensionEnabled: true,
  isFetchingThemeAppStatus: true,
  summary: {
    attributed_revenue: null,
    campaigns: null,
    last_campaign: null,
    subscribers: {
      total: null,
    },
  },
  reports: {
    isFetching: true,
    filters: {
      timeFilter: [
        startOfDay(subDays(new Date(), 30)),
        new Date(),
        {
          type: 'fixed',
          value: DaysInSeconds.LAST_30_DAYS,
          label: 'Last 30 days',
        },
      ],
      granularity: ReportsGranularity.DAILY,
    },
    subscriberGrowthBreakdown: [],
    totalSubsGained: 0,
    subsCountryBreakdown: [],
    totalAttributedRevenue: 0,
  },
});

const home = createModel<RootModel>()({
  state: initialState(),

  effects: dispatch => ({
    async fetchReport() {
      const { data, error } = await fetchSummaryAPI();
      if (error) return;

      this.storeState({ summary: data });
    },

    async fetchThemeAppExtensionStatus() {
      this.setSetState({
        isFetchingThemeAppStatus: true,
      });
      const { data, error } = await fetchThemeAppExtensionStatusAPI();

      if (error) {
        this.setSetState({
          isFetchingThemeAppStatus: false,
        });
        return;
      }

      this.setSetState({
        isThemeAppExtensionEnabled: !data.show_theme_app_ext_step,
        isFetchingThemeAppStatus: false,
      });
    },

    async fetchTotalAttributedRevenue(_, rootState) {
      const {
        settings: { attributionWindow },
      } = rootState;

      const { totalAttributedRevenue, error } =
        await fetchTotalAttributedRevenueAPI(attributionWindow);

      if (error) {
        dispatch.saveToast.showError('Error fetching attribution');
        return;
      }

      this.storeReports({ totalAttributedRevenue });
    },

    async fetchSubscriberGrowthReport(payload: {
      timeWindow: number | { start: Date; end: Date };
      granularity: ReportsGranularity;
      didGranularityChange?: boolean;
    }) {
      const {
        subscriberGrowthBreakdown,
        totalSubsGained,
        subsCountryBreakdown,
        error,
      } = await fetchSubscriberGrowthReportAPI(payload);

      if (error) {
        dispatch.saveToast.showError('Error fetching report');
        return;
      }

      if (payload.didGranularityChange) {
        this.storeReports({
          subscriberGrowthBreakdown,
          isFetching: false,
        });
        return;
      }

      this.storeReports({
        subscriberGrowthBreakdown,
        totalSubsGained,
        subsCountryBreakdown,
        isFetching: false,
      });
    },

    async toggleNewDashboard({ handleRefresh }: { handleRefresh: () => void }) {
      const { error } = await toggleNewDashboardAPI();
      if (error) {
        dispatch.saveToast.showError('Error switching dashboard');
      } else {
        handleRefresh();
      }
    },

    updateFiltersAndFetchGrowthReport(
      payload: { timeFilter?: TimeFilter; granularity?: ReportsGranularity },
      rootState,
    ) {
      this.updateFilters(payload);
      this.storeReports({ isFetching: true });

      // eslint-disable-next-line
      let { timeFilter, granularity } = rootState.home.reports.filters;

      const [_, __, { value: timeWindow }] = payload.timeFilter || timeFilter;
      granularity = payload.granularity || granularity;

      // This method is called with either the new timeFilter or the new granularity. In the case of the latter,
      // we optimize the request payload to avoid fetching redundant reports
      this.fetchSubscriberGrowthReport({
        timeWindow,
        granularity,
        didGranularityChange: Boolean(payload.granularity),
      });
    },

    async toggleOnboardingStatusForMerchant(status: WebpushOnboardingStatus) {
      try {
        const { error } = await toggleOnboardingStatusForMerchantAPI(status);

        if (error) {
          dispatch.saveToast.showError(
            'webpush_onboarding_modal_onboarding_status_error',
          );
          return;
        }
      } catch (error) {
        dispatch.saveToast.showError(
          'webpush_onboarding_modal_onboarding_status_error',
        );
      }
    },
  }),

  reducers: {
    dismissAppSetupCard(state: HomepageReport): HomepageReport {
      return {
        ...state,
        isThemeAppExtensionEnabled: true,
      };
    },
    storeState(state: HomepageReport, payload: any): HomepageReport {
      return {
        ...state,
        ...payload,
        isFetching: false,
      };
    },
    // TODO: Repalce setSetState with setSetState
    setSetState(state: HomepageReport, payload: any): HomepageReport {
      return {
        ...state,
        ...payload,
      };
    },
    storeReports(state: HomepageReport, payload: any): HomepageReport {
      return {
        ...state,
        reports: {
          ...state.reports,
          ...payload,
        },
      };
    },
    updateFilters(state, payload) {
      return {
        ...state,
        reports: {
          ...state.reports,
          filters: {
            ...state.reports.filters,
            ...payload,
          },
        },
      };
    },
  },
});

export default home;
