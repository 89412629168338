import { post, get, put } from './axios';

const ENDPOINT_PREFIX = '/dashboard/segment';
export const fetchActiveSegmentAPI = () => get(`${ENDPOINT_PREFIX}/`);

export const fetchAllSegmentsAPI = () =>
  get(`${ENDPOINT_PREFIX}/?states=active,pending,archived`);

export const fetchCampaignsForSegmentAPI = ({ id, offset }) =>
  get(`${ENDPOINT_PREFIX}/${id}/campaign/?limit=10&offset=${offset}`);

export const saveSegmentAPI = ({ id, ...data }) =>
  put(`${ENDPOINT_PREFIX}/${id}`, data);

export const saveNewSegmentAPI = data => post(`${ENDPOINT_PREFIX}/`, data);

export const fetchSegmentSizeAPI = data =>
  post(`${ENDPOINT_PREFIX}/estimate/`, data);

export const changeSegmentStateAPI = (segmentId, action) =>
  post(`${ENDPOINT_PREFIX}/${segmentId}/state-change/?action=${action}`, null);
