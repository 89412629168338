import { getStorage } from 'src/lib/storage';
import { get, getUser, post, put } from './axios';

export const setCompanyLogoAPI = async (url: string) => {
  const { error, status } = await post('/dashboard/preferences/', {
    preferences: [
      {
        key: 'company_logo',
        value: url,
      },
    ],
  });

  if (error)
    return {
      error,
      data: null,
    };

  if (status !== 200) throw Error('Something went wrong. Please try again.');

  // update session
  const storage = getStorage();
  const loggedInUser = storage.get('user');
  if (loggedInUser && loggedInUser.website) {
    loggedInUser.website.company_logo = url;
    storage.set('user', loggedInUser);
  }

  return {
    data: {
      company_logo: url,
    },
    error: null,
  };
};

export const setNotificationBadgeAPI = async (url: string) => {
  const { error, status } = await post('/dashboard/preferences/', {
    preferences: [
      {
        key: 'notification_badge',
        value: url,
      },
    ],
  });

  if (error)
    return {
      error,
      data: null,
    };

  if (status !== 200) throw Error('Something went wrong. Please try again.');

  // update session
  const storage = getStorage();
  const loggedInUser = storage.get('user');
  if (loggedInUser && loggedInUser.website) {
    loggedInUser.website.notification_badge = url;
    storage.set('user', loggedInUser);
  }

  return {
    data: {
      notification_badge: url,
    },
    error: null,
  };
};

export const fetchConsumptionAPI = () =>
  post('/dashboard/consumption/?breakdown=true', null);

export const fetchConsumptionV2API = () =>
  get('/dashboard/consumption/?breakdown=true', null, { version: 'v2' });

export const fetchGILBreakdownAPI = () => get('/dashboard/gil/breakdown/');

interface FCMData {
  sender_id: string;
  fcm_api_key: string;
}

export const setFCMAPI = async (data: FCMData) => {
  const user = getUser();
  const {
    data: responseData,
    error,
    status,
  } = await post(
    `/api/v1/website/${user.website.subdomain}/config/fcm/`,
    data,
    {
      baseURL: `${process.env.NEXT_PUBLIC_API_ENDPOINT}`,
    },
  );

  if (error)
    return {
      error,
      data: null,
    };

  if (status === 400) return { data: null, error };

  return { data: responseData, error: null };
};

export const fetchFCMAPI = async () => {
  const user = getUser();
  const { data, error } = await get(
    `/api/v1/website/${user.website.subdomain}/config/fcm/`,
    null,
    { baseURL: `${process.env.NEXT_PUBLIC_API_ENDPOINT}` },
  );

  if (error)
    return {
      error,
      data: null,
    };

  return { data, error: null };
};

type NotificationAction = {
  enabled: boolean;
  text: string;
};

interface PrivacyData {
  admin_access: boolean;
  customer_id: boolean;
  ip: string;
  location: boolean;
  notification_preference: {
    enabled: boolean;
    message: string;
    actions: {
      access_data: NotificationAction;
      delete_data: NotificationAction;
      unsubscribe: NotificationAction;
    };
  };
}

export const setPrivacyAPI = async (data: PrivacyData) => {
  const user = getUser();
  const { data: responseData, error } = await post(
    `/api/v1/website/${user.website.subdomain}/config/privacy/`,
    data,
    {
      baseURL: `${process.env.NEXT_PUBLIC_API_ENDPOINT}`,
    },
  );

  if (error)
    return {
      error,
      data: null,
    };

  return { data: responseData, error: null };
};

export const fetchPrivacyAPI = async () => {
  const user = getUser();
  const { data, error } = await get(
    `/api/v1/website/${user.website.subdomain}/config/privacy/`,
    null,
    {
      baseURL: `${process.env.NEXT_PUBLIC_API_ENDPOINT}`,
    },
  );

  if (error)
    return {
      error,
      data: null,
    };

  return { data, error: null };
};

export const fetchReportsAPI = () => get(`/dashboard/merchant-report/`);

export const sendReportEmailAPI = reportId =>
  post(`/dashboard/merchant-report/${reportId}/email/`, null);

export const saveReportAPI = report =>
  put(`/dashboard/merchant-report/${report.id}`, report);

export const fetchAttributionWindowAPI = () =>
  get('/dashboard/preferences/?keys=attribution_window');

export const saveAttributionWindowAPI = payload =>
  post('/dashboard/preferences/', payload);
