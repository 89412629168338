import { DaysInSeconds } from 'src/components/HistoricalDaterangePicker/types';
import { ReportTypes } from 'src/components/ReportSaver/models';
import { CampaignType } from 'src/modules/campaigns/models';
import { transformAttributionSettingsRequest as getAttributionWindow } from 'src/modules/settings/models';
import { get, post, put, del, patch } from '../axios';
import {
  getTimeWindow,
  makeReportsRequest,
  makeAutomationReportRequest,
  transformTotalAutomationStats,
  transformAllReportsResponse,
  makeCampaignsReportsRequest,
  makeAutomationOrderRequest,
} from './helpers';

export const fetchReportsAPI = async (nextUrl?: string) => {
  let response;

  if (!nextUrl) {
    response = await get('/dashboard/report/merchantreports/');
  } else {
    response = await get(nextUrl);
  }

  if (response.error) return { data: null, error: response.error };

  // Convert to redux reportSaver shape
  const transformedResults = response.data.data.map(
    transformAllReportsResponse,
  );

  return {
    data: { ...response.data, results: transformedResults },
    error: response.error,
  };
};

export const fetchSubscriberGrowthReportAPI = async ({
  timeWindow: window,
  granularity,
  didGranularityChange = false,
}) => {
  const timeWindow = getTimeWindow(window);

  const reportQuery: AnyObject[] = [
    {
      name: ReportTypes.SUBSCRIBER_GROWTH,
      params: {
        time_window: timeWindow,
        granularity,
      },
    },
  ];

  // If granularity is switched, we don't need to query for these reports again
  if (!didGranularityChange)
    reportQuery.push(
      {
        name: ReportTypes.SUBSCRIBERS_GAINED,
        params: {
          time_window: timeWindow,
        },
      },
      {
        name: ReportTypes.SUBSCRIBERS_COUNTRY_BREAKDOWN,
        params: {
          time_window: timeWindow,
          subscriber_features: ['country'],
        },
      },
    );

  const reportData = btoa(JSON.stringify(reportQuery));

  const { data, error } = await get(
    `/dashboard/report/?report_data=${reportData}`,
  );

  if (error || data.find(a => a.status === 'failed'))
    return { data, error: true };

  const subscriberGrowthBreakdown = data[0].report.filter(
    breakdownObj => breakdownObj.date !== 'total',
  );

  if (didGranularityChange)
    return {
      subscriberGrowthBreakdown,
    };

  const totalSubsGained = data[1].report[0].subscribers_gained;
  const subsCountryBreakdown = data[2].report;

  return {
    subscriberGrowthBreakdown,
    totalSubsGained,
    subsCountryBreakdown,
  };
};

export const fetchTotalAutomationStatsAPI = async (
  { value },
  attributionSettings,
) => {
  const attribution_window = getAttributionWindow(attributionSettings);

  const time_window = getTimeWindow(value);

  const reportData = btoa(
    JSON.stringify([
      {
        name: ReportTypes.AUTOMATION_PERF,
        params: {
          time_window,
          attribution_window,
        },
      },
    ]),
  );

  const { data, error } = await get(
    `/dashboard/report/?report_data=${reportData}`,
  );

  if (error || data.find(a => a.status === 'failed'))
    return { data, error: error || true };

  return transformTotalAutomationStats(data);
};

export const fetchACRReportsAPI = async (payload, attributionSettings) =>
  makeAutomationReportRequest('abandoned_cart', payload, attributionSettings);

export const fetchBAReportsAPI = async (payload, attributionSettings) =>
  makeAutomationReportRequest(
    'browse_abandonment',
    payload,
    attributionSettings,
  );

export const fetchACROrderDataAPI = async (payload, attributionSettings) =>
  makeAutomationOrderRequest('abandoned_cart', payload, attributionSettings);

export const fetchBAOrderDataAPI = async (payload, attributionSettings) =>
  makeAutomationOrderRequest(
    'browse_abandonment',
    payload,
    attributionSettings,
  );

export const fetchReportingSegmentsAPI = async () => {
  const timeWindow = getTimeWindow(DaysInSeconds.ALL_TIME);

  const reportData = btoa(
    JSON.stringify([
      {
        name: ReportTypes.CAMPAIGN_SEGMENTS,
        params: {
          time_window: timeWindow,
        },
      },
    ]),
  );

  const { data, error } = await get(
    `/dashboard/report/?report_data=${reportData}`,
  );

  if (error || data.find(a => a.status === 'failed'))
    return { data, error: error || true };

  const segmentsList = data[0].report.map(
    ({
      segment_id: id,
      segment_name: name,
      campaigns_sent: campaignsSent,
    }) => ({
      id,
      name,
      campaignsSent,
    }),
  );

  return { segmentsList };
};

export const fetchTotalCampaignStatsAPI = async (
  campaignStates: CampaignType[],
  { timeFilter: { value }, segmentIds, campaignTypes },
  attributionSettings,
) => {
  const attribution_window = getAttributionWindow(attributionSettings);

  const time_window = getTimeWindow(value);

  const segment_ids = segmentIds.map(id => +id);

  const reportData = window.btoa(
    JSON.stringify(
      campaignStates.map(state => ({
        name: ReportTypes.TOTAL_CAMPAIGN_PERF,
        params: {
          time_window,
          attribution_window,
          segment_ids,
          campaign_types: campaignTypes,
          campaign_state: state,
        },
      })),
    ),
  );

  const { data, error } = await get(
    `/dashboard/report/?report_data=${reportData}`,
  );

  if (error || data.find(report => report.status === 'failed'))
    return { reports: null, error: error || true };

  return Object.fromEntries(
    campaignStates.map((state, index) => [state, data[index].report[0]]),
  );
};

export const fetchSentCampaignsAPI = (payload, attributionSettings) =>
  makeCampaignsReportsRequest('sent', payload, attributionSettings);

export const fetchScheduledCampaignsAPI = (payload, attributionSettings) =>
  makeCampaignsReportsRequest('scheduled', payload, attributionSettings);

export const fetchPausedCampaignsAPI = (payload, attributionSettings) =>
  makeCampaignsReportsRequest('paused', payload, attributionSettings);

export const fetchTotalAttributedRevenueAPI = async attributionSettings => {
  const attribution_window = getAttributionWindow(attributionSettings);

  // Total Revenue will always consider an "All Time" time window
  const timeWindow = getTimeWindow(DaysInSeconds.ALL_TIME);

  const reportData = btoa(
    JSON.stringify([
      {
        name: ReportTypes.REVENUE_GENERATED,
        params: {
          time_window: timeWindow,
          attribution_window,
        },
      },
    ]),
  );

  const { data, error } = await get(
    `/dashboard/report/?report_data=${reportData}`,
  );

  if (error || data.find(a => a.status === 'failed'))
    return { data, error: error || true };

  return {
    totalAttributedRevenue: data[0].report[0].generated_revenue,
    error: null,
  };
};

export const saveReportAPI = async (payload, reportState) =>
  makeReportsRequest({
    method: post,
    url: `/dashboard/report/merchantreports/?sendnow=${payload.sendNow}`,
    reportState,
    filters: payload,
  });

export const updateReportAPI = (payload, reportState) => {
  const { id } = reportState;
  return makeReportsRequest({
    method: put,
    url: `/dashboard/report/merchantreport/${id}`,
    reportState,
    filters: payload,
  });
};

export const sendReportNowAPI = id =>
  post(`/dashboard/report/merchantreport/${id}`, null);

export const patchReportAPI = payload =>
  patch(`/dashboard/report/merchantreport/${payload.id}`, payload);

export const deleteReportAPI = id =>
  del(`/dashboard/report/merchantreport/${id}`);
