import Cookie from 'js-cookie';

// default validity time of a cookie from time of its creation
const ttl = 365;

export default {
  get(name: string) {
    const cookie = Cookie.get(name);
    if (!cookie) return null;

    return JSON.parse(cookie);
  },
  set(name: string, value: any) {
    Cookie.set(name, value, { expires: ttl });
  },
  remove(name: string) {
    Cookie.remove(name);
  },
  clear(name = 'user') {
    Cookie.remove(name);
  },
  clearAll() {
    Object.keys(Cookie.get()).forEach(this.remove);
  },
};
