interface Props {
  color?: any;
  style?: any;
  size?: number;
}

export const Android = ({ color = '#A4C639', style, size = 20 }: Props) => (
  <svg width={size} height={size} viewBox='0 0 20 20' fill='none' style={style}>
    <path
      d='M4.02466 6.55359C3.45404 6.55359 3 7.0225 3 7.6118V12.0197C3 12.609 3.45404 13.0779 4.02466 13.0779C4.59527 13.0779 5.04932 12.609 5.04932 12.0197V7.6118C5.04932 7.0225 4.59527 6.55359 4.02466 6.55359ZM15.9753 6.55359C15.4047 6.55359 14.9507 7.0225 14.9507 7.6118V12.0197C14.9507 12.609 15.4047 13.0779 15.9753 13.0779C16.546 13.0779 17 12.609 17 12.0197V7.6118C17 7.0225 16.546 6.55359 15.9753 6.55359Z'
      fill={color}
    />
    <path
      d='M8.29272 11.5838C7.72211 11.5838 7.26807 12.0527 7.26807 12.642V17.0499C7.26807 17.6392 7.72211 18.1081 8.29272 18.1081C8.86334 18.1081 9.31738 17.6392 9.31738 17.0499V12.642C9.31738 12.0527 8.86334 11.5838 8.29272 11.5838ZM11.7072 11.5838C11.1366 11.5838 10.6825 12.0527 10.6825 12.642V17.0499C10.6825 17.6392 11.1366 18.1081 11.7072 18.1081C12.2778 18.1081 12.7319 17.6392 12.7319 17.0499V12.642C12.7319 12.0527 12.2778 11.5838 11.7072 11.5838Z'
      fill={color}
    />
    <path
      d='M7.16295 1.00001C7.13873 1.00026 7.11616 1.00672 7.09391 1.01945C7.02084 1.06128 6.99681 1.14799 7.03742 1.22364L7.75923 2.57031C6.3705 3.31657 5.43248 4.73476 5.4306 6.36238H14.5694C14.5675 4.73476 13.6295 3.31657 12.2407 2.57031L12.9625 1.22364C13.0032 1.14799 12.9791 1.06128 12.9061 1.01945C12.8838 1.00672 12.8612 1.00025 12.837 1.00001C12.7852 0.999491 12.736 1.02625 12.7083 1.07779L11.9771 2.43905C11.3789 2.16503 10.708 2.01123 9.99998 2.01123C9.29196 2.01123 8.62102 2.16503 8.02285 2.43905L7.29162 1.07779C7.26395 1.02625 7.2148 0.999494 7.16295 1.00001ZM5.4306 6.7351V13.5754C5.4306 14.2074 5.92329 14.7163 6.53529 14.7163H13.4647C14.0767 14.7163 14.5694 14.2074 14.5694 13.5754V6.7351H5.4306Z'
      fill={color}
    />
    <path
      d='M7.89101 3.84729C7.68239 3.84729 7.5097 4.02563 7.5097 4.24109C7.5097 4.45654 7.68239 4.63488 7.89101 4.63488C8.09963 4.63488 8.27231 4.45654 8.27231 4.24109C8.27231 4.02563 8.09963 3.84729 7.89101 3.84729ZM12.1089 3.84729C11.9003 3.84729 11.7276 4.02563 11.7276 4.24109C11.7276 4.45654 11.9003 4.63488 12.1089 4.63488C12.3175 4.63488 12.4902 4.45654 12.4902 4.24109C12.4902 4.02563 12.3175 3.84729 12.1089 3.84729Z'
      fill='white'
    />
  </svg>
);
