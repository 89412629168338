import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useGlobalState } from 'src/hooks/use-global-state';
import { gotoNext } from 'src/lib/goto-next';
import { isSmallScreen } from 'src/lib/utils';

export const GlobalStateInitializer = ({ children }) => {
  const { actions } = useGlobalState('common');

  const {
    state: { isLoggedIn },
  } = useGlobalState('user');
  const router = useRouter();

  const handleShareWithPushOwl = () => {
    try {
      const redirectURL = new URL(window.location.href);
      const nextParam = redirectURL.searchParams.get('next');
      if (nextParam) {
        gotoNext(redirectURL.searchParams.get('next'));
      }
    } catch (error) {
      router.replace('/');
    }
  };

  useEffect(() => {
    if (!isLoggedIn) return;

    handleShareWithPushOwl();
  }, [isLoggedIn]);

  useEffect(() => {
    const resizer = () => {
      if (isSmallScreen()) {
        actions.setIsSmallScreen(true);
      } else {
        actions.setIsSmallScreen(false);
      }
    };

    window.addEventListener('resize', resizer);

    return () => {
      window.removeEventListener('resize', resizer);
    };
  }, []);

  return children;
};
