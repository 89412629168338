import styled from 'styled-components';

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: var(--p-z-index-5);
`;

export const Modal = styled.div`
  z-index: var(--p-z-index-6);
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -20%);
`;

export const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: var(--p-border-radius-200);

  #view-all-features {
    display: none;
  }

  @media (max-width: 768px) {
    margin: auto;

    #view-all-features {
      display: block;
    }
  }
`;

export const PricingContainer = styled.div`
  border: 1px solid #ddd;
  border-radius: var(--p-border-radius-200);

  @media (max-width: 768px) {
    display: none;
  }
`;

export const PricingTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-radius: var(--p-border-radius-200);
  min-width: 350px;
`;

export const TableHeader = styled.th`
  padding: var(--p-space-150) var(--p-space-100);
  padding-left: var(--p-space-300);

  text-align: left;
  background-color: #f8f8f8;
  font-weight: bold;
`;

export const TableCell = styled.td`
  text-align: left;
  padding: var(--p-space-200) var(--p-space-300);
  padding-left: var(--p-space-300);
`;

export const SectionHeader = styled(TableCell)`
  font-weight: 600;
  padding: var(--p-space-150) var(--p-space-100);
  padding-left: var(--p-space-300);
`;

export const Impressions = styled.div`
  background: var(--p-color-bg-surface-secondary);
  border: 1px solid var(--p-color-border-secondary);
  height: 56px;
  border-radius: var(--p-border-radius-100);
  display: grid;
  grid-template-columns: 50px 1fr;
`;

export const InverseButton = styled.button`
  color: white;
  backround: transparent;
  border: none;
  cursor: pointer;
`;
