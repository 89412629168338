function setError(obj: any, error) {
  return {
    ...obj,
    result: false,
    errors: {
      ...obj.errors,
      ...error,
    },
  };
}

// Iterates through all the languages and their values
// to see which one is empty.
const getLanguagesWithEmptyLabel = multilingualLabels => {
  return Object.entries(multilingualLabels).reduce((acc, langArr) => {
    const [languageIsoCode, label] = langArr;

    if (!label) {
      return acc.concat(languageIsoCode);
    }

    return acc;
  }, []);
};

export const isValidOptinsState = (data: any) => {
  let retVal: any = {
    result: true,
    errors: {
      title: {},
      description: {},
      noButtonLabel: {},
      yesButtonLabel: {},
    },
  };

  const emptyTitleLangs = getLanguagesWithEmptyLabel(
    data.twoStep.multilingual_title,
  );

  const emptyDescriptionLangs = getLanguagesWithEmptyLabel(
    data.twoStep.multilingual_description,
  );
  const yesButtonEmptyLabelLangs = getLanguagesWithEmptyLabel(
    data.twoStep.yesButton.multilingual_label,
  );
  const noButtonEmptyLabelLangs = getLanguagesWithEmptyLabel(
    data.twoStep.noButton.multilingual_label,
  );

  if (data.active === 'twoStep' && emptyTitleLangs.length) {
    emptyTitleLangs.forEach(langIsoCode => {
      retVal = setError(retVal, {
        title: {
          [langIsoCode]: 'Please add a title',
        },
      });
    });
  }

  if (data.active === 'twoStep' && emptyDescriptionLangs.length) {
    emptyDescriptionLangs.forEach(langIsoCode => {
      retVal = setError(retVal, {
        description: {
          [langIsoCode]: 'Please add a description',
        },
      });
    });
  }

  if (data.active === 'twoStep' && yesButtonEmptyLabelLangs.length) {
    yesButtonEmptyLabelLangs.forEach(langIsoCode => {
      retVal = setError(retVal, {
        yesButtonLabel: {
          [langIsoCode]: 'Please add a label for "Allow" button',
        },
      });
    });
  }

  if (data.active === 'twoStep' && noButtonEmptyLabelLangs.length) {
    noButtonEmptyLabelLangs.forEach(langIsoCode => {
      retVal = setError(retVal, {
        noButtonLabel: {
          [langIsoCode]: 'Please add a label for "Later" button',
        },
      });
    });
  }

  return retVal;
};
