import { createModel } from '@rematch/core';
import {
  fetchShippingConfigAPI,
  fetchShippingReportAPI,
  setShippingConfigAPI,
} from 'src/lib/api/automation/shipping';
import store from 'src/store';
import { RootModel } from 'src/store/models';
import { AutomationConfig } from './types';

interface ShippingState {
  isFetching: boolean;
  config: {
    enabled: boolean;
    metadata: AutomationConfig['metadata'];
  };
  report: {
    summary: {
      clicked: number;
      delivered: number;
    };
  };
}

const initialState = (): ShippingState => ({
  isFetching: true,
  config: {
    enabled: false,
    metadata: {
      title: '',
      description: '',
      redirect_url: '',
      actions: [],
    },
  },
  report: {
    summary: {
      clicked: 0,
      delivered: 0,
    },
  },
});

const shippingAutomation = createModel<RootModel>()({
  state: initialState(),

  effects: {
    async fetchConfig() {
      const { data, error } = await fetchShippingConfigAPI();

      if (!error) {
        this.storeState({ config: data });
      }
    },

    async fetchReport() {
      this.setFetching(true);

      const { data, error } = await fetchShippingReportAPI();

      if (!error) {
        this.storeState({ report: data });
      }
    },

    async toggle(payload: boolean, rootState) {
      const state = rootState.shippingAutomation;
      const newState = {
        ...state,
        config: {
          ...state.config,
          enabled: payload,
        },
      };

      this.storeState(newState);

      // make network request
      await setShippingConfigAPI(newState.config).then(res => {
        if (!res.error) {
          store.dispatch.saveToast.showDone('Changes saved');
        } else {
          store.dispatch.saveToast.showError('Error saving changes');
          this.storeState(state);
        }
      });
    },

    async saveConfigMetadata(
      payload: {
        actions: {
          title: string;
          redirect_url: string;
        }[];
        title: string;
        description: string;
        redirect_url: string;
        image: string;
      },
      rootState,
    ): Promise<{ data: any; error: any }> {
      const state = rootState.shippingAutomation;
      const newState = {
        ...state,
        config: {
          ...state.config,
          metadata: payload,
        },
      };

      // make network request
      return setShippingConfigAPI(newState.config).then(res => {
        if (!res.error) {
          this.storeState(newState);
          store.dispatch.saveToast.showDone('Changes saved');
        } else {
          store.dispatch.saveToast.showError('Error saving changes');
        }

        return res;
      });
    },
  },

  reducers: {
    setFetching(state: ShippingState, isFetching: boolean): ShippingState {
      return {
        ...state,
        isFetching,
      };
    },
    storeState(state: ShippingState, payload): ShippingState {
      return {
        ...state,
        ...payload,
        isFetching: false,
      };
    },
  },
});

export default shippingAutomation;
