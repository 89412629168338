/* eslint-disable no-console */

import { getAxios } from 'src/lib/api/axios';
import { getStorage } from 'src/lib/storage';
import store from 'src/store';
import { debugLog, logErrorToSentry } from '../debug-utils';

const storage = getStorage();

const getCurrentWebsite = (userDetails, currentSubdomain: string) => {
  if (userDetails.websites) {
    return userDetails.websites.find(
      (site: AnyObject) => site.subdomain === currentSubdomain,
    );
  }

  return userDetails.website;
};

// v3 API (latest) gives a reduced payload, v2 gives untruncated payload
const getAuthenticateEndpoint = ({
  platform,
  useLatestVersion = false,
}: {
  platform?: string;
  useLatestVersion?: boolean;
}) => {
  return `${process.env.NEXT_PUBLIC_API_ENDPOINT}/api/${
    useLatestVersion ? 'v3' : 'v2'
  }/pushowl/token/authenticate/?${platform ? `platform=${platform}` : ''}`;
};

const axiosRequest = ({
  payload,
  sentryReport,
  catchHandler,
  isSupportLogin,
}) => {
  const axios = getAxios();
  return axios(payload)
    .then(res => {
      const {
        data: { result, error, status },
      } = res;

      if (sentryReport && (error || result.detail)) {
        const { subdomain } = payload.data;

        logErrorToSentry({
          error: new Error('Login Failed'),
          extras: {
            subdomain,
            status,
            error,
          },
        });
      }

      if (error) return { error, user: null, isLoggedIn: false };

      const { account } = result;
      const { id, name, email, token } = account;

      const website = getCurrentWebsite(result, payload.data.subdomain);

      if (website) {
        const traits = {
          email: account.email,
          name: account.name,
          created_at: new Date(account.created_at).getTime() / 1000,
          subdomain: website.subdomain,
        };

        const segmentData = {
          subdomain: website.subdomain,
          isSupportLogin,
          traits,
        };

        // save data for later use
        storage.set('segmentdata', segmentData);

        store.dispatch.user.setSegmentData(segmentData);
      }

      const user: any = {
        id,
        name,
        email,
        website,
        // use token on authenticate payload if it exists or else use the auth token
        token: token || payload.data.authToken,
        accountFlags: account.flags,
      };
      user.websites = result.websites ? result.websites : [website];

      return { user, isLoggedIn: true };
    })
    .catch(catchHandler);
};
export interface LoginQueryParams {
  authToken: string;
  subdomain: string;
  platform: string;
}
export const loginAPI = async (
  queryParams: LoginQueryParams,
  useLatestVersion = false,
) => {
  const { authToken, subdomain, platform } = queryParams;

  const catchHandler = error => {
    logErrorToSentry({
      error: new Error('Login Failed'),
      extras: {
        queryParams,
        error,
      },
    });

    debugLog({
      message: 'redirect to login',
      data: { error },
    });

    if (platform !== 'shopify') {
      window.location.href = `${process.env.NEXT_PUBLIC_API_ENDPOINT}/auth/v1/pushowl/${platform}/?subdomain=${subdomain}`;
      return { user: null, isLoggedIn: false };
    }

    // redirect user to error page if there are errors in the login API
    throw new Error('AUTH_ERROR');
  };

  const payload: Record<string, string> = {
    subdomain,
  };

  payload.authToken = authToken;

  return axiosRequest({
    payload: {
      method: 'post',
      url: getAuthenticateEndpoint({
        platform,
        useLatestVersion,
      }),
      data: payload,
    },
    sentryReport: true,
    isSupportLogin: false,
    catchHandler,
  });
};
interface SupportLoginParams {
  subdomain: string;
  password: string;
  platform: string;
}

export const supportLoginAPI = (authData: SupportLoginParams) => {
  const catchHandler = error => {
    console.log(error);
    return { user: null, isLoggedIn: false };
  };

  return axiosRequest({
    payload: {
      method: 'post',
      url: `${process.env.NEXT_PUBLIC_API_ENDPOINT}/api/v1/auth/support/`,
      data: authData,
    },
    sentryReport: false,
    isSupportLogin: true,
    catchHandler,
  });
};

export const refetchUserAPI = async ({
  authToken,
  subdomain,
  platform,
  useLatestVersion = false,
}: {
  authToken: string;
  subdomain: string;
  platform: string;
  useLatestVersion?: boolean;
}) => {
  const segmentdata = storage.get('segmentdata');
  const isSupportLogin = segmentdata?.isSupportLogin;

  const payload: Record<string, string> = {
    subdomain,
  };

  payload.authToken = authToken;

  return axiosRequest({
    payload: {
      method: 'post',
      url: getAuthenticateEndpoint({
        platform,
        useLatestVersion,
      }),
      data: payload,
    },
    sentryReport: true,
    isSupportLogin,
    catchHandler: (error: any) => {
      console.log(error);
    },
  });
};
