import posthog from 'posthog-js';

export const identify = (identifier: string, traits: AnyObject) => {
  if (typeof window?.analytics?.identify === 'function')
    window.analytics.identify(identifier, traits);
};

export const trackPage = () => {
  if (typeof window?.analytics?.page === 'function') window.analytics.page();

  const { pathname } = window.location;
  if (pathname && posthog) {
    const url = window.origin + pathname;

    posthog.capture('$pageview', {
      $current_url: `${url}${window.location.search}`,
    });
  }
};

export const simpleTrack = (identifier: string) => {
  if (typeof window?.analytics?.track === 'function')
    window.analytics.track(identifier);
};
