import { createModel } from '@rematch/core';
import { subSeconds } from 'date-fns';
import {
  dateRangeFilterOptions,
  DaysInSeconds,
  TimeFilter,
} from 'src/components/HistoricalDaterangePicker/types';
import { OldTimeFilter } from 'src/components/ReportSaver/models';
import {
  createContactAttributeAPI,
  fetchContactAttributesAPI,
} from 'src/lib/api/brevo';
import {
  fetchSubscriberListAPI,
  fetchSubscriberReportAPI,
} from 'src/lib/api/subscribers';
import { ChannelType, CONTACT_ATTRIBUTE_BLACKLIST } from 'src/lib/constants';
import { CustomAttribute } from 'src/modules/optins/models/types';
import { RootModel } from 'src/store/models';

interface SubscribersReport {
  isFetchingFilteredData: boolean;
  subscriberLimit: number;
  subscribers: {
    isFetching: boolean;
    data: Array<AnyObject>;
  };
  report: {
    isFetching: boolean;
    countries: Array<{ country: string; count: number }>;
    subscribers: {
      daily_breakdown: Array<{
        created_date: string;
        subscriber_gain: number;
        subscriber_loss: number;
        previous_day_total_subscribers: number;
      }>;
      summary: {
        filtered_total: number;
        overall_enabled_total: number;
        last_week: number;
        unsubscribes: number;
      };
    };
  };
  timeFilter: {
    label?: string;
    type: string;
    value: number | AnyObject;
  };
  contactAttributes: {
    isFetching: boolean;
    isCreating?: boolean;
    attributes: CustomAttribute[];
  };
}

const initialState = (): SubscribersReport => ({
  isFetchingFilteredData: false,
  subscriberLimit: 0,
  subscribers: {
    isFetching: true,
    data: [],
  },
  report: {
    isFetching: true,
    subscribers: {
      summary: {
        filtered_total: 0,
        overall_enabled_total: 0,
        last_week: 0,
        unsubscribes: 0,
      },
      daily_breakdown: [],
    },
    countries: [],
  },
  timeFilter: dateRangeFilterOptions.find(
    option => option.value === DaysInSeconds.LAST_30_DAYS,
  ),
  contactAttributes: {
    isFetching: false,
    isCreating: false,
    attributes: [],
  },
});

const subscribers = createModel<RootModel>()({
  state: initialState(),

  effects: dispatch => ({
    async fetchReport() {
      const end_date = new Date();
      const start_date = subSeconds(end_date, DaysInSeconds.LAST_30_DAYS);

      const { data, error } = await fetchSubscriberReportAPI({
        start_date,
        end_date,
      });

      if (error) {
        dispatch.saveToast.showError('Error fetching report');
        return;
      }

      this.storeReport(data);
    },

    async fetchSubscriberList(payload: { channel?: ChannelType }) {
      this.toggleSubscriberListFetching(true);
      const { data, error } = await fetchSubscriberListAPI(payload?.channel);

      if (error) {
        dispatch.saveToast.showError('Error fetching subscribers');
        return;
      }

      this.storeSubscriberList(data);
      this.toggleSubscriberListFetching(false);
    },

    async applyTimeFilter(payload: {
      start_date: Date;
      end_date: Date;
      // TODO:aftermigration - use the new time filter type only
      timeFilter: TimeFilter | OldTimeFilter;
    }) {
      this.setTimeFilter({ timeFilter: payload.timeFilter });
      const start_date = new Date(payload.start_date);
      const end_date = new Date(payload.end_date);

      this.setIsFetchingFilteredData(true);

      const { data, error } = await fetchSubscriberReportAPI({
        start_date,
        end_date,
      });
      this.setIsFetchingFilteredData(false);

      if (error) {
        dispatch.saveToast.showError('Error fetching report');
        return;
      }

      this.storeReport(data);
    },

    async fetchContactAttributes(_, rootState) {
      const { attributes } = rootState.subscribers.contactAttributes;

      dispatch.subscribers.setContactAttribites({
        isFetching: true,
        attributes,
      });
      const { data, error } = await fetchContactAttributesAPI();

      if (data && !error) {
        dispatch.subscribers.setContactAttribites({
          isFetching: false,
          isCreating: false,
          attributes:
            data?.contact_attributes?.filter(
              attr => !CONTACT_ATTRIBUTE_BLACKLIST.includes(attr.name),
            ) || [],
        });
        return;
      }
      if (error) {
        dispatch.subscribers.setContactAttribites({
          isFetching: false,
          isCreating: false,
          attributes: [],
        });
      }
    },

    async createCustomAttribute(
      payload: CustomAttribute,
      rootState,
    ): Promise<boolean> {
      const { contactAttributes } = rootState.subscribers;

      dispatch.subscribers.setContactAttribites({
        ...contactAttributes,
        isCreating: true,
      });
      let hasError = false;
      const { error } = await createContactAttributeAPI({
        name: payload.name,
        type: payload.type,
      });

      if (!error) {
        await dispatch.subscribers.fetchContactAttributes();
      } else {
        dispatch.subscribers.setContactAttribites({
          ...contactAttributes,
          isCreating: false,
        });
        hasError = true;
        dispatch.saveToast.showError(
          typeof error !== 'string'
            ? error?.message
            : 'create_contact_attr_error',
        );
      }

      return hasError;
    },
  }),

  reducers: {
    storeReport(state, payload: SubscribersReport['report']) {
      return {
        ...state,
        report: {
          isFetching: false,
          ...payload,
        },
      };
    },

    toggleSubscriberListFetching(state, isFetching: boolean) {
      return {
        ...state,
        subscribers: {
          ...state.subscribers,
          isFetching,
        },
      };
    },

    storeSubscriberList(state, data: SubscribersReport['subscribers']['data']) {
      return {
        ...state,
        subscribers: {
          isFetching: false,
          data,
        },
        subscriberLimit: data.length > 50 ? 50 : data.length,
        isFetching: false,
      };
    },

    setTimeFilter(state, payload: Partial<SubscribersReport>) {
      return {
        ...state,
        ...payload,
      };
    },

    setContactAttribites(
      state,
      payload: SubscribersReport['contactAttributes'],
    ) {
      return {
        ...state,
        contactAttributes: payload,
      };
    },

    showMoreSubscribers(state) {
      return {
        ...state,
        subscriberLimit:
          state.subscriberLimit + 50 < state.subscribers.data.length
            ? state.subscriberLimit + 50
            : state.subscribers.data.length,
      };
    },

    setIsFetchingFilteredData(
      state,
      payload: SubscribersReport['isFetchingFilteredData'],
    ) {
      return {
        ...state,
        isFetchingFilteredData: payload,
      };
    },
  },
});

export default subscribers;
