import { createModel } from '@rematch/core';
import { Device } from 'barn/components/DeviceSwitch';

interface AutomationFlowState {
  previewDevice: Device;
  notification: {
    isEditing: boolean;
    config: AnyObject;
  };
}
const initialState = () => ({
  previewDevice: Device.Android,
  notification: {
    isEditing: false,
    config: null,
  },
});

const automationFlow = createModel()({
  state: initialState(),

  reducers: {
    setPreviewDevice(
      state: AutomationFlowState,
      payload: Device,
    ): AutomationFlowState {
      return {
        ...state,
        previewDevice: payload,
      };
    },

    initNotificationEditing(
      state: AutomationFlowState,
      payload: AnyObject = {},
    ): AutomationFlowState {
      return {
        ...state,
        notification: {
          ...state.notification,
          isEditing: true,
          config: payload,
        },
      };
    },

    shutNotificationEditing(state: AutomationFlowState) {
      return {
        ...state,
        notification: {
          ...state.notification,
          isEditing: false,
        },
      };
    },
  },
});

export default automationFlow;
