import styled from 'styled-components';
import tokens from 'barn/tokens';

export const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #343744;
  color: ${tokens.colors.greys[8]};
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Helvetica,
    Arial,
    sans-serif,
    Apple Color Emoji,
    Segoe UI Emoji;
  font-size: ${tokens.fontSizes[4]};
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: ${tokens.zIndex[8]};
  margin: 0;
`;
