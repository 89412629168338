import { get, post } from '../axios';

// endpoints
const CONFIG_ENDPOINT = `/dashboard/config/browse-abandonment/notification/`;

// query helpers
export const fetchBAConfigAPI = () => get(CONFIG_ENDPOINT);

export const setBAConfigAPI = (data: {
  enabled: boolean;
  notification_configs: Array<AnyObject>;
}) => post(CONFIG_ENDPOINT, data);
