import { createModel } from '@rematch/core';
import { saveReportAPI, updateReportAPI } from 'src/lib/api/reports';
import RegexValidations from 'src/lib/regex-validations';
import { timeZoneList } from 'src/lib/timeZoneList';
import { getTimezone } from 'src/lib/utils';
import {
  AttributionAction,
  AttributionDurationLimits,
  AttributionWindow,
} from 'src/modules/settings/models';
import { RootModel } from 'src/store/models';
import { DaysInSeconds, TimeFilter } from '../HistoricalDaterangePicker/types';

export type OldTimeFilter =
  | {
      type: 'fixed';
      value: DaysInSeconds;
      label: string;
    }
  | { type: 'range'; value: { start: Date; end: Date }; label: string };

export enum AutomationNames {
  ABANDONED_CART = 'abandoned_cart',
  BROWSE_ABANDONMENT = 'browse_abandonment',
  WELCOME_NOTIFICATION = 'post_subscription',
  BACK_IN_STOCK = 'back_in_stock',
  PRICE_DROP = 'price_drop',
  SHIPPING_NOTIFICATION = 'fulfillment_complete',
}

export enum ReportTypes {
  CAMPAIGNS_SENT = 'total_campaigns_sent',
  SUBSCRIBERS_GAINED = 'total_subscribers_gained',
  REVENUE_GENERATED = 'total_revenue_generated',
  IMPRESSIONS_CONSUMED = 'total_impressions_consumed',
  SUBSCRIBER_GROWTH = 'subscriber_growth',
  SUBSCRIBERS_COUNTRY_BREAKDOWN = 'subscriber_breakdown',
  CAMPAIGN_PERF = 'campaign_perf',
  TOTAL_CAMPAIGN_PERF = 'total_campaign_perf',
  CAMPAIGN_ORDER_DATA = 'campaign_attributed_orders',
  CAMPAIGN_SEGMENTS = 'all_segment_sent_campaign_report',
  AUTOMATION_PERF = 'meta_automation_overall_perf',
  AUTOMATION_REMINDER_PERF = 'automation_reminder_perf',
  AUTOMATION_ORDER_DATA = 'automation_attributed_orders',
  CUSTOM = 'custom',
}

export enum CampaignTypes {
  REGULAR = 'regular',
  FLASH = 'flash',
  SMART = 'smart',
  ALL = 'all',
}

export const campaignTypes = [
  { label: 'Regular Campaign', value: CampaignTypes.REGULAR },
  { label: 'Flash Sale', value: CampaignTypes.FLASH },
  { label: 'Smart Delivery', value: CampaignTypes.SMART },
];

export enum ReportsGranularity {
  DAILY = 'day',
  WEEKLY = 'week',
  MONTHLY = 'month',
}

export const reportsGranularity = [
  { label: 'Daily', value: ReportsGranularity.DAILY },
  { label: 'Weekly', value: ReportsGranularity.WEEKLY },
  { label: 'Monthly', value: ReportsGranularity.MONTHLY },
];

export enum DaysOfWeek {
  SUN = '0',
  MON = '1',
  TUE = '2',
  WED = '3',
  THU = '4',
  FRI = '5',
  SAT = '6',
}

export const daysOfWeek = [
  { label: 'Monday', value: DaysOfWeek.MON },
  { label: 'Tuesday', value: DaysOfWeek.TUE },
  { label: 'Wednesday', value: DaysOfWeek.WED },
  { label: 'Thursday', value: DaysOfWeek.THU },
  { label: 'Friday', value: DaysOfWeek.FRI },
  { label: 'Saturday', value: DaysOfWeek.SAT },
  { label: 'Sunday', value: DaysOfWeek.SUN },
];

export enum ReportState {
  SENT = 'sent',
  SCHEDULED = 'scheduled',
  PAUSED = 'paused',
}

interface SettingsObject {
  action: AttributionAction;
  duration: number;
  window: AttributionWindow;
}

export interface ReportSaverState {
  id: number;
  state: ReportState;
  reportType: ReportTypes | '';
  reportName: string;
  granularity: ReportsGranularity;
  timeFilter: TimeFilter | OldTimeFilter;
  isRecurringSelected: boolean;
  recipientList: string;
  attributionSettings: SettingsObject[];
  dayOfMonth: number;
  dayOfWeek: DaysOfWeek;
  deliveryTime: Date;
  timeZone: string;
  isSaveable: boolean;
  errors: {
    reportName: string | null;
    recipientList: string | null;
  };

  /**
   * During edit, we'll receive these from API, and forward them back when updating the report.
   * During initial save, these values will be retrieved from other stores, see effect saveReport
   */
  subscriberGrowthGranularity?: ReportsGranularity;
  campaignTypes?: CampaignTypes[];
  segmentIds?: number[];
  automationName?: AutomationNames;
}

export function getDefaultTimeZone() {
  const tz = getTimezone().location;
  return timeZoneList.find(zone => zone.value === tz)?.value || 'GMT';
}

const isValidState = ({ recipientList, attributionSettings }) => {
  const areAttributionSettingsValid = !attributionSettings
    .map(isValidAttributionSetting)
    .includes(false);
  return isValidRecipientList(recipientList) && areAttributionSettingsValid;
};

export const isValidRecipientList = recipientList =>
  !recipientList
    .split(',')
    .map(a => RegexValidations.email(a.trim()))
    .includes(false);

export const isValidAttributionSetting = ({ window, duration, action }) => {
  if (action === AttributionAction.ALL) return true;

  if (!duration) return false;

  return (
    (window === AttributionWindow.DAYS &&
      duration <= AttributionDurationLimits.DAYS) ||
    (window === AttributionWindow.HOURS &&
      duration <= AttributionDurationLimits.HOURS)
  );
};

type ReportSaverKeys = Partial<ReportSaverState>;

const updateDependentProperties = (
  payload: ReportSaverKeys,
): ReportSaverKeys => {
  const newPayload = { ...payload };
  const { isRecurringSelected, timeFilter } = newPayload;

  if (isRecurringSelected === undefined && timeFilter === undefined)
    return newPayload;

  // If the time range selected is absolute, turn recurring off and set state to SENT
  if (timeFilter?.type === 'range') {
    newPayload.isRecurringSelected = false;
    newPayload.state = ReportState.SENT;
  }

  // If recurring settings are being changed, set the state accordingly
  if (typeof isRecurringSelected === 'boolean')
    newPayload.state = isRecurringSelected
      ? ReportState.SCHEDULED
      : ReportState.SENT;

  return newPayload;
};

export const initialReportState: ReportSaverState = {
  id: null,
  state: ReportState.SENT,
  reportType: '',
  reportName: '',
  granularity: ReportsGranularity.DAILY,
  timeFilter: {
    type: 'fixed',
    value: DaysInSeconds.LAST_30_DAYS,
    label: 'Last 30 days',
  },
  isRecurringSelected: false,
  recipientList: '',
  attributionSettings: [
    {
      action: AttributionAction.CLICK,
      duration: 24,
      window: AttributionWindow.HOURS,
    },
  ],
  dayOfMonth: 1,
  dayOfWeek: DaysOfWeek.MON,
  deliveryTime: new Date(),
  timeZone: getDefaultTimeZone(),
  isSaveable: false,
  errors: {
    reportName: null,
    recipientList: null,
  },
};

const reportSaver = createModel<RootModel>()({
  state: initialReportState,
  effects: dispatch => ({
    async saveReport(
      payload: {
        sendNow?: boolean;
        attributedCampaignId?: number;
        automationName?: AutomationNames;
      },
      rootState,
    ) {
      const {
        campaigns: {
          reports: { campaignTypes, segmentIds, segmentsList },
        },
        home: {
          reports: {
            filters: { granularity },
          },
        },
      } = rootState;

      const { error } = await saveReportAPI(
        {
          ...payload,
          campaignTypes,
          segmentIds:
            segmentIds.length === segmentsList.length ? [] : segmentIds,
          growthGranularity: granularity,
        },
        rootState.reportSaver,
      );

      if (error) dispatch.saveToast.showError('Error saving reports');
      else dispatch.saveToast.showDone('Saved to reports');
    },

    async updateReport(payload, rootState) {
      const { error } = await updateReportAPI(payload, rootState.reportSaver);

      if (error) dispatch.saveToast.showError('Error updating report');
      else dispatch.saveToast.showDone('Report Updated');

      // Edit only possible from the reports tab in settings; fetch again after update to refresh data.
      dispatch.settings.fetchReports();
    },

    setAttributionSettings(_, rootState) {
      const {
        settings: {
          attributionWindow: {
            attributionAction: action,
            attributionDuration: duration,
            attributionWindow: window,
          },
        },
      } = rootState;
      this.setState({ attributionSettings: [{ action, duration, window }] });
    },
  }),
  reducers: {
    setState(state, payload: Partial<ReportSaverState>) {
      const newPayload = updateDependentProperties(payload);

      const newState = { ...state, ...newPayload };

      return {
        ...newState,
        isSaveable: !!newState.reportName && isValidState(newState),
      };
    },
    setAttrSettingsProp<T extends keyof SettingsObject>(
      state,
      {
        index,
        key,
        newVal,
      }: { index: number; key: T; newVal: SettingsObject[T] },
    ) {
      const newSettings = [...state.attributionSettings];
      newSettings[index][key] = newVal;
      const newState = { ...state, attributionSettings: newSettings };

      return {
        ...newState,
        isSaveable: isValidState(newState),
      };
    },
    deleteFromAttributionSettings(state, { index }: { index: number }) {
      const newSettings = [...state.attributionSettings];
      newSettings.splice(index, 1);
      return {
        ...state,
        attributionSettings: newSettings,
      };
    },
    reset() {
      return initialReportState;
    },
    setErrors(state, payload: AnyObject) {
      return {
        ...state,
        errors: {
          ...state.errors,
          ...payload,
        },
      };
    },
  },
});

export default reportSaver;
