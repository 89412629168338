import { type OnboardingState } from 'src/modules/integrated-onboarding/models';
import { get, post, put } from './axios';

export const getBrandDetails = async () => get('/onboarding/brand/:url');

export const getIndustries = async () => get('/onboarding/industries');

type Payload = Omit<OnboardingState, 'currentStep'>;
export const updateOrCreateMerchantPreferences = async (
  payload: Payload,
  isPostRequest = false,
) => {
  let res = null;
  if (isPostRequest) {
    res = await post('/onboarding/preferences/', payload);
  } else {
    res = await put('/onboarding/preferences/', payload);
  }
  return res;
};

export const getMerchantPreferences = async () =>
  get('/onboarding/preferences/');
