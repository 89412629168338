export const timeZoneList = [
  {
    value: 'Pacific/Midway',
    label: 'GMT-11 Midway Island, Samoa',
  },
  {
    value: 'Pacific/Honolulu',
    label: 'GMT-10 Hawaii',
  },
  {
    value: 'America/Juneau',
    label: 'GMT-8 Alaska',
  },
  {
    value: 'America/Dawson',
    label: 'GMT-7 Dawson, Yukon',
  },
  {
    value: 'America/Phoenix',
    label: 'GMT-7 Arizona',
  },
  {
    value: 'America/Tijuana',
    label: 'GMT-7 Tijuana, Pacific Time',
  },
  {
    value: 'America/Boise',
    label: 'GMT-6 Mountain Time',
  },
  {
    value: 'America/Chihuahua',
    label: 'GMT-6 Chihuahua, La Paz, Mazatlan',
  },
  {
    value: 'America/Regina',
    label: 'GMT-6 Saskatchewan',
  },
  {
    value: 'America/Belize',
    label: 'GMT-6 Central America',
  },
  {
    value: 'America/Chicago',
    label: 'GMT-5 Central Time',
  },
  {
    value: 'America/Mexico_City',
    label: 'GMT-5 Guadalajara, Mexico City, Monterrey',
  },
  {
    value: 'America/Bogota',
    label: 'GMT-5 Bogota, Lima, Quito',
  },
  {
    value: 'America/Lima',
    label: 'GMT-5 Pittsburgh',
  },
  {
    value: 'America/Detroit',
    label: 'GMT-4 Eastern Time',
  },
  {
    value: 'America/Caracas',
    label: 'GMT-4 Caracas, La Paz',
  },
  {
    value: 'America/Santiago',
    label: 'GMT-4 Santiago',
  },
  {
    value: 'America/Sao_Paulo',
    label: 'GMT-3 Brasilia',
  },
  {
    value: 'America/Argentina/Buenos_Aires',
    label: 'GMT-3 Buenos Aires, Georgetown',
  },
  {
    value: 'America/St_Johns',
    label: 'GMT-2:30 Newfoundland and Labrador',
  },
  {
    value: 'America/Godthab',
    label: 'GMT-2 Greenland',
  },
  {
    value: 'Atlantic/Cape_Verde',
    label: 'GMT-1 Cape Verde Islands',
  },
  {
    value: 'Atlantic/Azores',
    label: 'GMT+0 Azores',
  },
  {
    value: 'GMT',
    label: 'GMT+0 UTC',
  },
  {
    value: 'Africa/Casablanca',
    label: 'GMT+0 Casablanca, Monrovia',
  },
  {
    value: 'Europe/London',
    label: 'GMT+1 Edinburgh, London',
  },
  {
    value: 'Europe/Dublin',
    label: 'GMT+1 Dublin',
  },
  {
    value: 'Europe/Lisbon',
    label: 'GMT+1 Lisbon',
  },
  {
    value: 'Atlantic/Canary',
    label: 'GMT+1 Canary Islands',
  },
  {
    value: 'Africa/Algiers',
    label: 'GMT+1 West Central Africa',
  },
  {
    value: 'Europe/Belgrade',
    label: 'GMT+2 Belgrade, Bratislava, Budapest, Ljubljana, Prague',
  },
  {
    value: 'Europe/Sarajevo',
    label: 'GMT+2 Sarajevo, Skopje, Warsaw, Zagreb',
  },
  {
    value: 'Europe/Brussels',
    label: 'GMT+2 Brussels, Copenhagen, Madrid, Paris',
  },
  {
    value: 'Europe/Amsterdam',
    label: 'GMT+2 Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
  },
  {
    value: 'Africa/Cairo',
    label: 'GMT+2 Cairo',
  },
  {
    value: 'Africa/Harare',
    label: 'GMT+2 Harare, Pretoria',
  },
  {
    value: 'Europe/Berlin',
    label: 'GMT+2 Frankfurt',
  },
  {
    value: 'Europe/Bucharest',
    label: 'GMT+3 Bucharest',
  },
  {
    value: 'Europe/Helsinki',
    label: 'GMT+3 Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius',
  },
  {
    value: 'Europe/Athens',
    label: 'GMT+3 Athens, Istanbul, Minsk',
  },
  {
    value: 'Asia/Jerusalem',
    label: 'GMT+3 Jerusalem',
  },
  {
    value: 'Europe/Moscow',
    label: 'GMT+3 Moscow, St. Petersburg, Volgograd',
  },
  {
    value: 'Asia/Kuwait',
    label: 'GMT+3 Kuwait, Riyadh',
  },
  {
    value: 'Africa/Nairobi',
    label: 'GMT+3 Nairobi',
  },
  {
    value: 'Asia/Baghdad',
    label: 'GMT+3 Baghdad',
  },
  {
    value: 'Asia/Dubai',
    label: 'GMT+4 Abu Dhabi, Muscat',
  },
  {
    value: 'Asia/Tehran',
    label: 'GMT+4:30 Tehran',
  },
  {
    value: 'Asia/Kabul',
    label: 'GMT+4:30 Kabul',
  },
  {
    value: 'Asia/Baku',
    label: 'GMT+5 Baku, Tbilisi, Yerevan',
  },
  {
    value: 'Asia/Yekaterinburg',
    label: 'GMT+5 Ekaterinburg',
  },
  {
    value: 'Asia/Karachi',
    label: 'GMT+5 Islamabad, Karachi, Tashkent',
  },
  {
    value: 'Asia/Calcutta',
    label: 'GMT+5:30 Chennai, Kolkata, Mumbai, New Delhi',
  },
  {
    value: 'Asia/Colombo',
    label: 'GMT+5:30 Sri Jayawardenepura',
  },
  {
    value: 'Asia/Kathmandu',
    label: 'GMT+5:45 Kathmandu',
  },
  {
    value: 'Asia/Dhaka',
    label: 'GMT+6 Astana, Dhaka',
  },
  {
    value: 'Asia/Almaty',
    label: 'GMT+6 Almaty, Novosibirsk',
  },
  {
    value: 'Asia/Rangoon',
    label: 'GMT+6:30 Yangon Rangoon',
  },
  {
    value: 'Asia/Bangkok',
    label: 'GMT+7 Bangkok, Hanoi, Jakarta',
  },
  {
    value: 'Asia/Krasnoyarsk',
    label: 'GMT+7 Krasnoyarsk',
  },
  {
    value: 'Asia/Shanghai',
    label: 'GMT+8 Beijing, Chongqing, Hong Kong, Urumqi',
  },
  {
    value: 'Asia/Kuala_Lumpur',
    label: 'GMT+8 Kuala Lumpur, Singapore',
  },
  {
    value: 'Asia/Taipei',
    label: 'GMT+8 Taipei',
  },
  {
    value: 'Australia/Perth',
    label: 'GMT+8 Perth',
  },
  {
    value: 'Asia/Irkutsk',
    label: 'GMT+8 Irkutsk, Ulaanbaatar',
  },
  {
    value: 'Asia/Seoul',
    label: 'GMT+9 Seoul',
  },
  {
    value: 'Asia/Tokyo',
    label: 'GMT+9 Osaka, Sapporo, Tokyo',
  },
  {
    value: 'Australia/Darwin',
    label: 'GMT+9:30 Darwin',
  },
  {
    value: 'Australia/Adelaide',
    label: 'GMT+9:30 Adelaide',
  },
  {
    value: 'Asia/Yakutsk',
    label: 'GMT+10 Yakutsk',
  },
  {
    value: 'Australia/Sydney',
    label: 'GMT+10 Canberra, Melbourne, Sydney',
  },
  {
    value: 'Australia/Brisbane',
    label: 'GMT+10 Brisbane',
  },
  {
    value: 'Australia/Hobart',
    label: 'GMT+10 Hobart',
  },
  {
    value: 'Asia/Vladivostok',
    label: 'GMT+10 Vladivostok',
  },
  {
    value: 'Pacific/Guam',
    label: 'GMT+10 Guam, Port Moresby',
  },
  {
    value: 'Asia/Magadan',
    label: 'GMT+11 Magadan, Solomon Islands, New Caledonia',
  },
  {
    value: 'Asia/Kamchatka',
    label: 'GMT+12 Kamchatka, Marshall Islands',
  },
  {
    value: 'Pacific/Fiji',
    label: 'GMT+12 Fiji Islands',
  },
  {
    value: 'Pacific/Auckland',
    label: 'GMT+12 Auckland, Wellington',
  },
  {
    value: 'Pacific/Tongatapu',
    label: "GMT+13 Nuku'alofa",
  },
];
