import { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import { debugLog } from 'src/lib/debug-utils';
import addMetaToUser from './addMetaToUser';
import { useGlobalState } from '../use-global-state';

/**
 * Inserting keys to prevent crash for users don't have the keys in their storage
 */
export const insertNewKeys = user => {
  const newUser = user;

  try {
    if (isEmpty(user || {})) {
      return newUser;
    }

    if (!user.website.website_plan) {
      newUser.website.website_plan = newUser.website.current_plan;
    }

    if (user.website.is_eligible_for_trial === undefined) {
      newUser.website.is_eligible_for_trial = user.website.trial.is_available;
    }
  } catch (error) {
    debugLog({
      message: 'user object error',
      data: {
        error,
        user,
      },
    });
  }

  return newUser;
};

/* useAuth hook */
const useAuth = () => {
  const {
    state: { user },
    actions,
  } = useGlobalState('user');

  const userWithMetaData = useMemo(
    () =>
      insertNewKeys(Object.keys(user).length > 0 ? addMetaToUser(user) : user),
    [user],
  );

  return {
    user: userWithMetaData,
    login: actions.authTokenLogin,
    supportLogin: actions.supportLogin,
    setWebsitePreference: actions.setWebsitePreference,
    logout: actions.logout,
  };
};
export default useAuth;
