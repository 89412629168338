import { createModel } from '@rematch/core';
import {
  fetchWelcomeConfigAPI,
  fetchWelcomeReportAPI,
  setWelcomeConfigAPI,
} from 'src/lib/api/automation/welcome';
import store from 'src/store';
import { RootModel } from 'src/store/models';
import { AutomationConfig } from './types';

interface WelcomeState {
  isFetching: boolean;
  config: {
    enabled: boolean;
    notification_configs?: Array<AutomationConfig>;
    metadata?: AutomationConfig['metadata'];
  };
  report: {
    summary: {
      clicked: number;
      delivered: number;
      scheduled: number;
    };
  };
  notificationInEdit: number;
}

const initialState = (): WelcomeState => ({
  isFetching: true,
  config: {
    enabled: false,
    notification_configs: [],
  },
  report: {
    summary: {
      clicked: 0,
      delivered: 0,
      scheduled: 0,
    },
  },
  notificationInEdit: null,
});

const welcomeAutomation = createModel<RootModel>()({
  state: initialState(),

  effects: {
    async fetchConfig() {
      const { data, error } = await fetchWelcomeConfigAPI();

      if (!error) {
        this.storeState({ config: data });
      }
    },
    async fetchReport() {
      this.setFetching(true);

      const { data, error } = await fetchWelcomeReportAPI();

      if (!error) {
        this.storeState({ report: data });
      }
    },

    async toggle(payload: boolean, rootState) {
      const state = rootState.welcomeAutomation;
      let notification_configs = state.config?.notification_configs || [];

      notification_configs = notification_configs.map(item => ({
        ...item,
        enabled: payload,
      }));

      const newState = {
        ...state,
        config: {
          ...state.config,
          notification_configs,
          enabled: payload,
        },
      };

      this.storeState(newState);
      // make network request
      await setWelcomeConfigAPI(newState.config).then(res => {
        if (!res.error) {
          store.dispatch.saveToast.showDone('Changes saved');
        } else {
          store.dispatch.saveToast.showError('Error saving changes');
          this.storeState(state);
        }
      });
    },

    async saveNotification(
      payload: AnyObject /* notification */,
      rootState,
    ): Promise<{ data: any; error: any }> {
      const { _id, ...restPayload } = payload;
      const state = rootState.welcomeAutomation;
      const notificationIndex = Number.isInteger(state.notificationInEdit)
        ? state.notificationInEdit
        : _id;
      const { notification_configs } = state.config;
      const notification = {
        ...notification_configs[notificationIndex],
        ...restPayload,
      };
      notification_configs[notificationIndex] = notification;

      const newState = {
        ...state,
        config: {
          ...state.config,
          notification_configs,
        },
        notificationInEdit: null,
      };

      // make network request
      return setWelcomeConfigAPI(newState.config).then(res => {
        if (!res.error) {
          this.storeState(newState);
          store.dispatch.saveToast.showDone('Changes saved');
        } else {
          store.dispatch.saveToast.showError('Error saving changes');
        }
        return res;
      });
    },
  },

  reducers: {
    setFetching(state: WelcomeState, isFetching: boolean): WelcomeState {
      return {
        ...state,
        isFetching,
      };
    },
    storeState(state: WelcomeState, payload): WelcomeState {
      return {
        ...state,
        ...payload,
        isFetching: false,
      };
    },
    initNotificationEditing(
      state: WelcomeState,
      payload: number,
    ): WelcomeState {
      return {
        ...state,
        notificationInEdit: payload,
      };
    },
    shutNotificationEditing(state: WelcomeState) {
      return {
        ...state,
        notificationInEdit: null,
      };
    },
  },
});

export default welcomeAutomation;
