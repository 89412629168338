import styled from 'styled-components';
import tokens from 'barn/tokens';
import { responsiveScreenBreakpoint } from 'barn/tokens/misc';

const DeviceSwitch = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 70px;
  background: ${tokens.colors.greys[6]};
  padding: ${tokens.space.padding[4]} 0 0;
  width: 64px;

  > * {
    margin-bottom: ${tokens.space.margin[6]};
  }

  @media (max-width: ${responsiveScreenBreakpoint}) {
    flex-direction: row;
    gap: ${tokens.space.margin[4]};
    width: max-content;
    padding: ${tokens.space.margin[4]};

    > * {
      margin-bottom: 0;
    }
  }
`;

const Segment = styled.button`
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
  border: 0;
  border-radius: 50%;
  background-color: ${tokens.colors.greys[8]};
  color: ${tokens.colors.greys[1]};
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 1;
  }

  &.active {
    border: 2px solid ${tokens.colors.greens[4]};
    opacity: 1;
  }
  > svg {
    width: 30px;
    height: 30px;
  }
`;

export default {
  DeviceSwitch,
  Segment,
};
