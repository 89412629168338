import Router from 'next/router';

/**
 * if received a valid base64 `next` query param
 */
export const gotoNext = function gotoNext(next: string) {
  try {
    const nextState = JSON.parse(window.atob(next));

    if ('path' in nextState && 'params' in nextState) {
      const { path } = nextState;
      const queryKeys = Object.keys(nextState.params);

      let gotoURL = `${path}${path.includes('?') ? '' : '?'}`;
      queryKeys.forEach((key, index) => {
        gotoURL += `${index > 0 ? '&' : ''}${key}=${nextState.params[key]}`;
      });

      Router.push(encodeURI(gotoURL));
    } else {
      Router.replace('/');
      throw new Error('Received an invalid `next` query parameter.');
    }
  } catch (error) {
    // eslint-disable-next-line
    console.log(error);
    Router.replace('/');
  }
};
