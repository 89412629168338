import { useEffect } from 'react';
import { isClientSide } from 'src/lib/utils';
import { useOwlyFeatureFlag } from './use-owly-feature-flag';

/**
 * overrides the document title to replace 'Brevo' with 'PushOwl Dashboard' in title
 */
export const useOverrideBrevoDocumentTitle = () => {
  const isMFEEnabled = useOwlyFeatureFlag('brevo_mfe');

  useEffect(() => {
    if (!isClientSide() || !isMFEEnabled) return;

    Object.defineProperty(document, 'title', {
      set(newValue: string) {
        let titleEle = document.getElementsByTagName('title')?.[0];
        if (!titleEle) {
          titleEle = document.createElement('title');
          document.head.appendChild(titleEle);
        }
        if (newValue !== '') {
          if (newValue.endsWith('Brevo')) {
            titleEle.innerHTML = newValue.replace('Brevo', 'PushOwl Dashboard');
          } else {
            titleEle.innerHTML = newValue;
          }
        }
      },

      get() {
        return (
          document.getElementsByTagName('title')?.[0]?.innerHTML ||
          'PushOwl Dashboard'
        );
      },
      configurable: true,
    });
  }, []);
};
