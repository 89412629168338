import { subDays } from 'date-fns';
import { ChannelType } from '../constants';
import { get } from './axios';

export const fetchSubscriberReportAPI = async (params?: any) => {
  const endDate = params?.end_date || new Date();
  const startDate = params?.start_date || subDays(endDate, 30);

  return get(
    `/dashboard/subscriber/report/?source=dashboard&start_time=${startDate.toISOString()}&end_time=${endDate.toISOString()}`,
  );
};

export const fetchSubscriberListAPI = (channel?: ChannelType) =>
  get(`/dashboard/subscriber/list/${channel ? `?channel=${channel}` : ``}`);
