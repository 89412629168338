export enum DaysInSeconds {
  LAST_24_HOURS = 86400,
  LAST_7_DAYS = 604800,
  LAST_30_DAYS = 2592000,
  LAST_90_DAYS = 7776000,
  ALL_TIME = 189216000,
}

export type TimeFilter =
  | { type: 'fixed'; value: DaysInSeconds; label: string }
  | { type: 'range'; value: { start: Date; end: Date }; label: string };

export const AllTimeTimeFilter: Extract<TimeFilter, { type: 'fixed' }> = {
  value: DaysInSeconds.ALL_TIME,
  label: 'All Time',
  type: 'fixed',
};

export const dateRangeFilterOptions: TimeFilter[] = [
  {
    value: DaysInSeconds.LAST_24_HOURS,
    label: 'Last 24 hours',
    type: 'fixed',
  },
  {
    value: DaysInSeconds.LAST_7_DAYS,
    label: 'Last 7 days',
    type: 'fixed',
  },
  {
    value: DaysInSeconds.LAST_30_DAYS,
    label: 'Last 30 days',
    type: 'fixed',
  },
  {
    value: DaysInSeconds.LAST_90_DAYS,
    label: 'Last 90 days',
    type: 'fixed',
  },
  AllTimeTimeFilter,
];
