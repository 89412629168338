import { CSSProperties } from 'react';
import { FormWidgetType } from 'src/lib/constants';
import { getTreeNodeById } from 'src/modules/optins/util/treeUtils';
import { ReservedContainerNodeId, ReservedElementNodeId } from './constants';
import {
  BaseTreeNode,
  Direction,
  Fill,
  Size,
  TreeNode,
  TreeNodeAlignment,
  TreeNodeByType,
  TreeNodeType,
} from './types';

export const transformAlignmentToFlexProps = (
  alignment?: TreeNodeAlignment,
  direction?: Direction,
): CSSProperties => {
  if (!alignment || alignment?.length === 0) {
    return {};
  }

  const flexProps: Record<string, string> = {};
  const alignmentArr = typeof alignment === 'string' ? [alignment] : alignment;

  if (direction === 'LR') {
    alignmentArr.forEach(alignmentType => {
      switch (alignmentType) {
        case 'center':
          flexProps.alignItems = 'center';
          break;
        case 'left':
          flexProps.justifyContent = 'flex-start';
          break;
        case 'right':
          flexProps.justifyContent = 'flex-end';
          break;
        case 'bottom':
          flexProps.alignItems = 'flex-end';
          break;
        case 'top':
          flexProps.alignItems = 'flex-start';
          break;
        default:
          return flexProps;
      }

      return {};
    });

    return flexProps;
  }

  alignmentArr.forEach(alignmentType => {
    switch (alignmentType) {
      case 'center':
        flexProps.justifyContent = 'center';
        break;
      case 'left':
        flexProps.alignItems = 'flex-start';
        break;
      case 'right':
        flexProps.alignItems = 'flex-end';
        break;
      case 'bottom':
        flexProps.justifyContent = 'flex-end';
        break;
      case 'top':
        flexProps.justifyContent = 'flex-start';
        break;

      default:
        return flexProps;
    }

    return {};
  });

  return flexProps;
};

export const getBackgroundImageProps = (url?: string): CSSProperties => {
  return url
    ? {
        backgroundImage: `url("${url}")`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
      }
    : {};
};

export const getFillAmount = (fill?: Fill) => {
  if (typeof fill === 'string') {
    return fill;
  }

  if (typeof fill === 'boolean') {
    return fill ? '50%' : '';
  }

  return '50%';
};

export const getInputTypeByNode = (nodeId: string) => {
  switch (nodeId) {
    case 'email':
      return 'email';
    case 'phone':
      return 'text';
    default:
      return 'text';
  }
};

export const getFontProps = (
  size?: Size,
  defaultSize = 14,
): React.CSSProperties => {
  const lineHeightMultiplier = 1.2;
  const defaultProps: React.CSSProperties = {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontSize: `${defaultSize}px`,
    lineHeight: `${defaultSize * lineHeightMultiplier}px`,
    paddingBlockEnd: '0px',
  };

  if (!size) {
    return defaultProps;
  }

  const bottomPadding =
    {
      '4xl': '10px',
      '3xl': '10px',
      '2xl': '8px',
      xl: '6px',
      lg: '2px',
      md: '0px',
      sm: '0px',
      xs: '0px',
      0: '0px',
    }[size] || '0px';

  const baseSize =
    {
      xs: 10,
      sm: 12,
      md: 16,
      lg: 20,
      xl: 26,
      '2xl': 30,
      '3xl': 36,
      '4xl': 42,
      0: 0,
    }[size] || defaultSize;

  const fontWeight = baseSize > 16 ? 'bold' : 'normal';

  return {
    ...defaultProps,
    fontSize: `${baseSize}px`,
    lineHeight: `${baseSize * lineHeightMultiplier}px`,
    fontWeight,
    paddingBlockEnd: bottomPadding,
  };
};

export const getPaddingProps = (size?: Size): React.CSSProperties => {
  if (!size) {
    return { padding: '6px' };
  }

  const baseSize =
    {
      xs: 2,
      sm: 4,
      md: 6,
      lg: 8,
      xl: 10,
      '2xl': 12,
      '3xl': 14,
      '4xl': 16,
      '0': 0,
    }[size] ?? 6;

  return {
    padding: `${baseSize}px`,
  };
};

export const getTextOverflowProps = (isFlyout: boolean): CSSProperties => {
  return {
    whiteSpace: isFlyout ? 'nowrap' : 'normal',
    // leave some gap for the close icon in teaser
    width: isFlyout ? 'calc(100% - 25px)' : 'unset',
  };
};

export const getFlexDirectionProps = (
  isSmallScreen: boolean,
  dir?: Direction,
): CSSProperties => {
  if (isSmallScreen) {
    return {
      flexDirection: 'column',
    };
  }

  return {
    flexDirection: dir === 'LR' ? 'row' : 'column',
  };
};

export const getBoxRenderProps = (
  node: TreeNodeByType<TreeNodeType.BOX>,
  isSmallScreen: boolean,
): React.CSSProperties => {
  const {
    align,
    dir,
    bgUrl: bgURL,
    pad,
    bgColor,
    css,
    fill,
    hidden,
  } = node.attr || {};

  const shouldHideBgImage = bgURL && isSmallScreen;

  const alignmentProps = transformAlignmentToFlexProps(align, dir);
  const flexDirProps = getFlexDirectionProps(isSmallScreen, dir);
  const bgProps = !shouldHideBgImage ? getBackgroundImageProps(bgURL) : {};
  const paddingProps = getPaddingProps(pad);
  const fillAmount = getFillAmount(fill);

  let displayProp = 'flex';
  const considerHidden = typeof node.attr.hidden === 'boolean' ? hidden : false;

  if (considerHidden) {
    displayProp = 'none';
  } else if (shouldHideBgImage) {
    displayProp = 'none';
  }

  return {
    position: 'relative',
    background: bgColor || '#fff',
    display: displayProp,
    flex: fillAmount,
    flexShrink: bgURL ? 0 : 1,
    // flex: `1 ${bgURL ? 0 : 1} ${fillAmount}`,
    // adding minWidth: 0 to fix overflow issue and flex shrink issue
    // ref: https://defensivecss.dev/tip/flexbox-min-content-size/
    minWidth: 0,
    ...flexDirProps,
    ...paddingProps,
    ...alignmentProps,
    ...bgProps,
    ...(css || {}),
  };
};

export const isImageHidden = (node: TreeNode) => {
  const imageNode = getTreeNodeById(node, ReservedContainerNodeId.IMAGE);

  return typeof imageNode?.attr?.hidden === 'boolean'
    ? imageNode.attr.hidden
    : false;
};

export const getWidgetDimensionProps = (
  isSmallScreen: boolean,
  noMinHeight = false,
): Record<FormWidgetType, CSSProperties> => {
  if (isSmallScreen) {
    return {
      [FormWidgetType.STEP_1]: {
        width: '300px',
        minHeight: '450px',
      },
      [FormWidgetType.STEP_2]: {
        width: '300px',
        minHeight: '450px',
      },
      [FormWidgetType.TEASER]: {
        width: '360px',
        height: '64px',
      },
    };
  }

  return {
    [FormWidgetType.STEP_1]: {
      width: '530px',
      minHeight: noMinHeight ? 'unset' : '500px',
    },
    [FormWidgetType.STEP_2]: {
      width: '530px',
      minHeight: noMinHeight ? 'unset' : '500px',
    },
    [FormWidgetType.TEASER]: {
      width: '360px',
      height: '64px',
    },
  };
};

export const isCustomFieldNode = (node: TreeNode | BaseTreeNode | string) =>
  (typeof node === 'string' ? node : node.id).startsWith(
    ReservedElementNodeId.CUSTOM_FIELD,
  );
