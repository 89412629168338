import {
  endOfDay,
  minutesToMilliseconds,
  startOfDay,
  format,
  parse,
  isValid,
  isPast,
  roundToNearestMinutes,
  isEqual,
  isBefore,
  startOfYear,
  setYear,
  isAfter,
  subSeconds,
} from 'date-fns';
import { TimeFilter } from 'src/components/HistoricalDaterangePicker/types';

/**
 * @param interval granularity
 * @param referenceDate base date to keep as reference
 * @param disablePastTimes disables all the time options that are past the current time
 * @returns list of options
 */
export const generateGranularTimeIntervalList = (
  interval = 30,
  referenceDate: Date,
  disablePastTimes: boolean,
) => {
  const now = roundToNearestMinutes(Date.now(), {
    nearestTo: interval,
    roundingMethod: 'floor',
  }).getTime();
  const start = startOfDay(referenceDate);
  const end = endOfDay(referenceDate);
  const timeList: {
    value: Date;
    label: string;
  }[] = [];

  const initialTime = disablePastTimes ? now : start.getTime();

  /**
   * chances of initial time becoming greater than endtime is hypothetical
   * if by any chance that happens, this if condition ensures that we don't run into an infinite for loop
   */
  if (initialTime < end.getTime()) {
    for (
      let currTime = initialTime;
      currTime < end.getTime();
      currTime += minutesToMilliseconds(interval)
    ) {
      timeList.push({
        value: new Date(currTime),
        label: format(currTime, 'hh:mm a'),
      });
    }
  }

  return timeList;
};

/**
 * takes reference date as a base to do time operations
 * @param time time-like string to parse to JS Date
 * @param referenceDate Date
 * @returns Date | null
 */
export const convertTimeLikeStringToDateObject = (
  time: string,
  referenceDate: Date,
  disablePastTimes: boolean,
) => {
  try {
    const parsedDate = parse(time, "hh:mm aaaaa'M'", startOfDay(referenceDate));
    if (isValid(parsedDate)) {
      if (disablePastTimes) {
        return isPast(parsedDate) ? null : parsedDate;
      }

      return parsedDate;
    }
    return null;
  } catch (e) {
    return null;
  }
};

/**
 * returns the number in string prefixed format (eg: 1st, 2nd, 3rd, 4th)
 * @param number
 * @returns string
 */
export const nthNumber = (number: number) => {
  if (number > 3 && number < 21) return 'th';
  switch (number % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};

export const isBeforeOrEqual = (date1: Date, date2: Date) =>
  isBefore(date1, date2) || isEqual(date1, date2);
export const isAfterOrEqual = (date1: Date, date2: Date) =>
  isAfter(date1, date2) || isEqual(date1, date2);

/**
 * returns null if any condition in minDate and maxDate does not satisfy the parsedDate
 * @param dateString date-like string to parse to a JS Date
 * @param shouldBeBetween minDate and maxDate (both optional)
 * @returns Date | null
 */
export const convertDateLikeStringToDateObject = (
  dateString: string,
  shouldBeBetween?: { minDate?: Date; maxDate?: Date },
) => {
  try {
    const parsedDate = parse(
      dateString,
      'MMMM d, yyyy',
      startOfDay(new Date()),
    );
    if (isValid(parsedDate)) {
      if (shouldBeBetween) {
        const { minDate, maxDate } = shouldBeBetween;
        const startOfMinDate = startOfDay(
          minDate || startOfYear(setYear(new Date(), 2016)),
        );
        const endOfMaxDate = endOfDay(maxDate);

        // if constraint between two days
        if (isValid(startOfMinDate) && isValid(endOfMaxDate)) {
          return isBeforeOrEqual(parsedDate, endOfMaxDate) &&
            isAfterOrEqual(parsedDate, startOfMinDate)
            ? parsedDate
            : null;
        }

        // if constraint to be after a minDate
        if (isValid(startOfMinDate)) {
          return isAfterOrEqual(parsedDate, startOfMinDate) ? parsedDate : null;
        }

        // if constraint to be before a maxDate
        if (isValid(endOfMaxDate)) {
          return isBeforeOrEqual(parsedDate, endOfMaxDate) ? parsedDate : null;
        }
      }

      return parsedDate;
    }
    return null;
  } catch (e) {
    return null;
  }
};

/**
 * assumes that the time filters points to the past (x days ago) and not future (after x days)
 * and converts fixed point in time to a range(start & end) that is relative to today
 * @param timeFilter TimeFilter (fixed)
 * @returns timeFilter TimeFilter (range)
 */
export const convertFixedToRange = (timeFilter: TimeFilter) => {
  if (timeFilter.type === 'fixed') {
    return {
      ...timeFilter,
      type: 'range',
      value: {
        start: subSeconds(new Date(), timeFilter.value),
        end: new Date(),
      },
    };
  }

  return timeFilter;
};
