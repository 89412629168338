// @ts-nocheck

export const loadAnalytics = intercomData => {
  // Segment
  const segmentScript = `!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="${process.env.NEXT_PUBLIC_SEGMENT_API}";;analytics.SNIPPET_VERSION="4.15.3";
  analytics.load("${process.env.NEXT_PUBLIC_SEGMENT_API}", { integrations: { Intercom: false, FullStory: false } });
  analytics.page();
  }}();`;

  // Canny
  const cannyScript = `!function(w,d,i,s){function l(){if(!d.getElementById(i)){var f=d.getElementsByTagName(s)[0]
    ,e=d.createElement(s);e.type="text/javascript",e.async=!0,e.src="https://canny.io/sdk.js",
    f.parentNode.insertBefore(e,f)}}if("function"!=typeof w.Canny){var c=function(){c.q.push(arguments)};
    c.q=[],w.Canny=c,"complete"===d.readyState?l():w.attachEvent?w.attachEvent("onload",l)
    :w.addEventListener("load",l,!1)}}(window,document,"canny-jssdk","script");`;

  const intercomScript = `
    var w = window;
    var ic = w.Intercom;
    if (typeof ic === "function") {
        ic('reattach_activator');
        ic('update', w.intercomSettings);
    } else {
        var d = document;
        var i = function() {
            i.c(arguments);
        };
        i.q = [];
        i.c = function(args) {
            i.q.push(args);
        };
        w.Intercom = i;
        var l = function() {
            var s = d.createElement('script');
            s.type = 'text/javascript';
            s.async = true;
            s.src = 'https://widget.intercom.io/widget/${process.env.NEXT_PUBLIC_INTERCOM_API_KEY}';
            var x = d.getElementsByTagName('script')[0];
            x.parentNode.insertBefore(s, x);
        };
        l();
      }`;

  const segmentScriptTag = document.createElement('script');
  segmentScriptTag.type = 'text/javascript';
  segmentScriptTag.text = segmentScript;
  document.body.appendChild(segmentScriptTag);

  const cannyScriptTag = document.createElement('script');
  cannyScriptTag.type = 'text/javascript';
  cannyScriptTag.text = cannyScript;
  document.body.appendChild(cannyScriptTag);

  if (typeof window.Canny === 'function') {
    const changelogConfig = {
      appID: '5dd9767659129f2717088579',
      position: 'bottom',
      align: 'right',
    };

    window.Canny('initChangelog', changelogConfig);
    if (typeof window.Canny === 'function') window.Canny('closeChangelog');
  }

  const intercomScriptTag = document.createElement('script');
  intercomScriptTag.type = 'text/javascript';
  intercomScriptTag.text = intercomScript;
  document.body.appendChild(intercomScriptTag);
  if (window.Intercom) {
    window.Intercom('boot', intercomData);

    const urlParams = new URLSearchParams(window.location.search);
    const openIntercom = urlParams.get('open_intercom');
    const message = urlParams.get('message');

    if (openIntercom === 'true' && message) {
      window.Intercom('showNewMessage', decodeURIComponent(message));
    }
  }
};

export const RB2B_SCRIPT_SRC =
  '!function () {var reb2b = window.reb2b = window.reb2b || []; if (reb2b.invoked) return;reb2b.invoked = true;reb2b.methods = ["identify", "collect"]; reb2b.factory = function (method) {return function () {var args = Array.prototype.slice.call(arguments); args.unshift(method);reb2b.push(args);return reb2b;};}; for (var i = 0; i < reb2b.methods.length; i++) {var key = reb2b.methods[i];reb2b[key] = reb2b.factory(key);} reb2b.load = function (key) {var script = document.createElement("script");script.type = "text/javascript";script.async = true; script.src = "https://s3-us-west-2.amazonaws.com/b2bjsstore/b/" + key + "/reb2b.js.gz"; var first = document.getElementsByTagName("script")[0]; first.parentNode.insertBefore(script, first);}; reb2b.SNIPPET_VERSION = "1.0.1";reb2b.load("Y46DJ4HR3M61");}();';
