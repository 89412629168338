import styled from 'styled-components';
import tokens from 'barn/tokens';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${tokens.zIndex[6]};
  background: rgba(0, 0, 0, 0.8);
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Message = styled.div`
  background: ${tokens.colors.greys[8]};
  padding: 5rem;
  font-size: 2rem;
`;
export default { Wrapper, Message };
