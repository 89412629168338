export default function addMetaToUser(user: AnyObject) {
  const { website } = user;

  // FIXME: find out why website is undefined sometimes
  if (!website) {
    return { ...user };
  }

  // FIXME: find out why currency_format is empty or has no {{}} sometimes
  const amountPlaceholderText =
    website.currency_format && website.currency_format.match(/{{/)
      ? /{{(.*?)}}/.exec(website.currency_format)[0]
      : '';

  const {
    current_plan: { plan_type: planType },
  } = website;

  return {
    ...user,
    amountPlaceholderText,
    currency: website.currency_format,
    plan: website.website_plan,
    isBasic: planType !== 'business' && planType !== 'enterprise',
    isBusiness: planType === 'business',
    isEnterprise: planType === 'enterprise',
    // We can group stores more info here https://www.notion.so/pushowl/Global-Impression-Limit-system-GIL-3497d777cf1a49eaa7e98b926c07b46d
    isGILStore: !!user.website.gil_parent_subdomain,
    isGILParent:
      user.website.gil_parent_subdomain &&
      user.website.subdomain === user.website.gil_parent_subdomain,
    isGILChild:
      user.website.gil_parent_subdomain &&
      user.website.gil_parent_subdomain !== user.website.subdomain,
  };
}
