import styled from 'styled-components';

const Wheel = styled.g`
  animation: clockwise 1.5s infinite linear;
  transform-origin: center;
  transform-box: fill-box;

  @keyframes clockwise {
    from {
      transform: rotateZ(0deg);
    }
    to {
      transform: rotateZ(360deg);
    }
  }
`;

export const Owly = styled.g`
  animation: bounce 1s infinite linear;
  @keyframes bounce {
    0 {
      transform: translateY(0px);
    }
    50% {
      transform: translateY(4px);
    }
    100% {
      transform: translateY(0px);
    }
  }
`;

export const Plant = styled.path`
  animation: move 1s infinite linear;
  @keyframes move {
    0% {
      transform: translateX(-100px);
    }
    100% {
      transform: translateX(500px);
    }
  }
`;

export const LoadingOwly = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='270 300 700 500'
      width='400px'
      height='400px'
    >
      <defs>
        <clipPath id='clip-path'>
          <circle fill='none' cx='627.63' cy='565.2' r='278.66' />
        </clipPath>
      </defs>
      <rect fill='#343744' width='1267.14' height='1037.11' />
      <circle fill='#fff' cx='627.63' cy='567.2' r='270.69' />

      <g clipPath='url(#clip-path)'>
        <Plant
          fill='#175a0c'
          d='M456.63,836.18A164.28,164.28,0,0,1,424,789.83c-3.4-7.22-6.32-15-6.07-22.93s4.31-16.27,11.6-19.52c8-3.57,17.48-.28,24.72,4.66s13.4,11.5,21.19,15.51a396.59,396.59,0,0,1,21.1-95.63c2.91-8.18,6.2-16.47,12.05-22.88s14.82-10.65,23.27-8.65c12.36,2.92,18.23,16.91,21.17,29.27a232.08,232.08,0,0,1,.71,104c7.43-18.9,27.88-31.81,48.14-30.39,3,.21,6.11.77,8.43,2.65,3.2,2.58,4.15,7,4.7,11a124.77,124.77,0,0,1-17.28,81.34c-1.61,2.61-3.44,5.28-6.17,6.68s-5.94,1.32-9,1.22l-119.62-4'
        />
        <line
          fill='none'
          stroke='#000'
          strokeLinecap='round'
          strokeMiterlimit='10'
          strokeWidth='2px'
          x1='522.34'
          y1='659.68'
          x2='509.78'
          y2='840.45'
        />
        <path
          fill='none'
          stroke='#000'
          strokeLinecap='round'
          strokeMiterlimit='10'
          strokeWidth='2px'
          d='M585.13,770.59s-25.78,55.66-74,49'
        />
        <path
          fill='none'
          stroke='#000'
          strokeLinecap='round'
          strokeMiterlimit='10'
          strokeWidth='2px'
          d='M441,770.59s10.58,40.13,68.09,54.51'
        />
      </g>
      <Owly>
        <g id='back_hand' data-name='back hand'>
          <g id='skate_board' data-name='skate board'>
            <path d='M414,782.52s-47.59,1.46-47.59-20.16c0-15.87,31.4,16.85,58.5,16.85H824.15s27.1,1.65,41.65-16.19,26.11,20.15-34.71,20.15S414,782.52,414,782.52Z' />
          </g>
          <g id='right_shoe' data-name='right shoe'>
            <polygon
              fill='#fed139'
              points='530.78 735.2 526.32 769.57 549.29 768.37 544 724.46 530.78 735.2'
            />
            <path d='M462.7,779.21s-1.63-8.48,26.45-13.41,30.44-6.92,31-8.2,8.39,13.67,31.36,2.92c3.28-1.46,12,6.29,12.4,18.69Z' />
          </g>
        </g>

        <g id='left_shoe' data-name='left shoe'>
          <path
            fill='#fed139'
            d='M744.29,719.5s22.47,9.75,33.05,27.51c0,0-12.05,18.94-19.5,14.46s-5-30.4-25.78-37Z'
          />
          <path d='M703.15,778.73s-1.8-9.16,26.27-14.08,22.1-13.4,23.46-13.09c13.55,3.06,18.51-13.55,9.25-21.16,3.28-1.45,25.12,4,30.08,13.22l-15.49,24.54a27,27,0,0,1-23.13,12.58C734,780.54,703.15,778.73,703.15,778.73Z' />
          <line
            strokeWidth='4px'
            strokeMiterlimit='10'
            strokeLinecap='round'
            fill='none'
            stroke='#000'
            x1='325.93'
            y1='841.45'
            x2='906.29'
            y2='841.45'
          />
        </g>
        <g id='body'>
          <path
            strokeWidth='80px'
            strokeMiterlimit='10'
            strokeLinecap='round'
            fill='none'
            stroke='#000'
            d='M616.11,577.52s-102.82,15.4-77.73,135.37'
          />
          <path
            stroke='#f9c430'
            strokeWidth='50px'
            strokeMiterlimit='10'
            strokeLinecap='round'
            d='M537.81,426.34c76,12.12,89.82,10.13,161.2,35.29'
          />
          <path
            fill='#fed139'
            d='M460.55,368.18c-12.45,16.23-21.86,32-12,49.89,30,54.32,91.8,161.31,113.48,163.61,29.08,3.08,144.54-23.36,146.3-25.56S566,418.74,520.73,361A252.14,252.14,0,0,0,481,326.53C472.81,320.87,473.42,351.39,460.55,368.18Z'
          />
          <path
            fill='#f9c430'
            d='M511.31,363.12S637.49,507,636.19,539,545.75,570,545.75,570s11.46,16.2,30,11.64c0,0,81.22-9.83,130.26-24.26a2.46,2.46,0,0,0,1-4.12C684.71,531.55,544.61,394.81,520.73,361Z'
          />
          <ellipse
            fill='#fff'
            cx='474.74'
            cy='409.27'
            rx='24.24'
            ry='25.01'
            transform='translate(-34.33 43.57) rotate(-5.07)'
          />
          <path d='M470.45,411.29a6.17,6.17,0,0,1-.11-.62c-.06-.34,0,.31,0,.06s0-.26,0-.39a8.44,8.44,0,0,1,0-.87,6.18,6.18,0,0,1,0-.86l0-.27c0-.22-.08.38,0,0,0-.18.07-.36.11-.54s.09-.32.14-.47l.1-.27c0,.05-.15.29,0,.05s.13-.26.2-.39.11-.16.15-.24c.17-.28,0,0,0-.06s.24-.26.37-.38l.18-.15c-.25.2,0,0,0,0l.46-.25c.34-.19-.26.08.1,0a6.41,6.41,0,0,1,.75-.21c.07,0,.51-.07.24,0s.27,0,.34,0a8,8,0,0,1,.85,0c.25,0-.33-.08.07,0l.48.11c.16,0,.31.1.47.15l.18.07c-.34-.12-.16-.07-.08,0,.3.17.59.34.88.53.08.05.18.17-.05,0l.24.21a3.59,3.59,0,0,1,.39.39c.06.07.12.15.19.22s-.13-.2,0,.07.24.38.35.57l.12.23s.19.42.06.12c.09.21.17.43.24.65a11.52,11.52,0,0,1,.35,1.65,1.52,1.52,0,0,0,1.85,1.05,1.54,1.54,0,0,0,1-1.85,8.63,8.63,0,0,0-2.59-5.3,7.32,7.32,0,0,0-4.61-1.84,6.66,6.66,0,0,0-4.52,1.46,6.83,6.83,0,0,0-2.18,4.91,11.29,11.29,0,0,0,.27,3.38,1.5,1.5,0,1,0,2.89-.8Z' />
          <path
            fill='#1e1e1e'
            d='M446.31,413.78s-13.25,22.34-7.35,39.79a1.32,1.32,0,0,0,2.39.25c2.51-4.33,8.43-13.55,16.38-19.37Z'
          />
          <path d='M461.19,443.62c1.73,3,3.48,6.08,5.22,9.11q6.55,11.32,13.2,22.59,8.63,14.58,17.47,29c5.86,9.54,11.82,19,18,28.37a240.23,240.23,0,0,0,15,20.85c.48.57,1,1.14,1.45,1.69,1.28,1.45,3.4-.67,2.12-2.12-5.22-5.91-9.67-12.54-14.1-19.11-6.13-9.11-12-18.41-17.78-27.75q-9-14.56-17.71-29.31-7-11.78-13.84-23.62c-2.13-3.68-4.11-7.49-6.37-11.09l-.09-.15a1.5,1.5,0,0,0-2.59,1.51Z' />
          <path
            fill='none'
            strokeMiterlimit='10'
            strokeWidth='80px'
            stroke='#262626'
            strokeLinecap='round'
            d='M672.24,573.09s-152.69,111,45.61,139.8'
          />
        </g>

        <g id='hand'>
          <path
            fill='#fed139'
            d='M705.16,508.16s4,9.48,4.63,13.44,8.37,7.06,11.24,0,2.74-14.33,15.42-10.13c12.06,4,15.95,8.21,18.38,2.24,1.36-3.32-8.87-4.54-10.23-12.16-.71-4,12.72,4.71,19.94-.79,10.41-7.94-17.21-6.34-18.17-8.93-1.11-3,16.19,3.31,19.94-5.2,2.44-5.52-11.13-2.7-21-7.11s-25.55-11.68-36.13,1.1S705.16,508.16,705.16,508.16Z'
          />
          <path
            fill='#fed139'
            d='M533.13,409c48.51,8.52,98.13,18.87,143.37,39.51,15.28,6.78,30,14.26,44.41,22.06a21.68,21.68,0,0,1-20,38.45c-27.43-13.69-55.24-25.56-84.66-33.49-29.63-8.07-60.36-12.73-91.07-17.17a25,25,0,1,1,8-49.36Z'
          />
        </g>
      </Owly>

      <Wheel id='front-wheel'>
        <circle cx='464.06' cy='810.81' r='28.64' />
        <circle fill='#fff' cx='464.06' cy='810.81' r='13.29' />
        <path
          fill='none'
          stroke='#fff'
          strokeWidth='0.76px'
          strokeDasharray='11.38 11.38'
          strokeMiterlimit='10'
          d='M485.8,810.81a21.74,21.74,0,0,1-21.74,21.74c-28.83-1.15-28.83-42.34,0-43.48a21.74,21.74,0,0,1,21.74,21.74Z'
        />
      </Wheel>
      <Wheel id='back-wheel'>
        <circle cx='780.98' cy='811.81' r='28.64' />
        <circle fill='#fff' cx='780.98' cy='811.81' r='13.29' />
        <path
          fill='none'
          strokeMiterlimit='10'
          stroke='#fff'
          strokeWidth='0.76px'
          strokeDasharray='11.38 11.38'
          d='M802.72,810.81A21.74,21.74,0,0,1,781,832.55c-28.83-1.15-28.82-42.34,0-43.48a21.74,21.74,0,0,1,21.74,21.74Z'
        />
      </Wheel>
    </svg>
  );
};
