export const IOS = () => (
  <svg
    width='30'
    height='30'
    viewBox='0 0 30 30'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M3.05176e-05 7.82606C3.05176e-05 3.50383 3.50389 -3.05176e-05 7.82612 -3.05176e-05H22.1739C26.4962 -3.05176e-05 30 3.50383 30 7.82606V22.1739C30 26.4961 26.4962 30 22.1739 30H7.82612C3.50389 30 3.05176e-05 26.4961 3.05176e-05 22.1739V7.82606Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M22.1739 1.30432H7.82612C4.22426 1.30432 1.30438 4.2242 1.30438 7.82606V22.1739C1.30438 25.7757 4.22426 28.6956 7.82612 28.6956H22.1739C25.7758 28.6956 28.6957 25.7757 28.6957 22.1739V7.82606C28.6957 4.2242 25.7758 1.30432 22.1739 1.30432ZM7.82612 -3.05176e-05C3.50389 -3.05176e-05 3.05176e-05 3.50383 3.05176e-05 7.82606V22.1739C3.05176e-05 26.4961 3.50389 30 7.82612 30H22.1739C26.4962 30 30 26.4961 30 22.1739V7.82606C30 3.50383 26.4962 -3.05176e-05 22.1739 -3.05176e-05H7.82612Z'
      fill='black'
    />
    <path
      d='M6.11783 19.5652V13.1621H7.10165V19.5652H6.11783ZM6.61807 12.0949C6.42631 12.0949 6.26095 12.0296 6.122 11.8989C5.98582 11.7683 5.91773 11.6113 5.91773 11.4279C5.91773 11.2445 5.98582 11.0874 6.122 10.9568C6.26095 10.8262 6.42631 10.7609 6.61807 10.7609C6.80984 10.7609 6.97381 10.8262 7.10998 10.9568C7.24894 11.0874 7.31842 11.2445 7.31842 11.4279C7.31842 11.6113 7.24894 11.7683 7.10998 11.8989C6.97381 12.0296 6.80984 12.0949 6.61807 12.0949Z'
      fill='black'
    />
    <path
      d='M16.2405 15.2965C16.2405 16.1969 16.078 16.9751 15.7528 17.6309C15.4276 18.2868 14.9816 18.7926 14.4146 19.1484C13.8477 19.5041 13.2001 19.682 12.472 19.682C11.7439 19.682 11.0963 19.5041 10.5294 19.1484C9.96243 18.7926 9.51638 18.2868 9.19122 17.6309C8.86606 16.9751 8.70348 16.1969 8.70348 15.2965C8.70348 14.396 8.86606 13.6178 9.19122 12.962C9.51638 12.3061 9.96243 11.8003 10.5294 11.4445C11.0963 11.0888 11.7439 10.911 12.472 10.911C13.2001 10.911 13.8477 11.0888 14.4146 11.4445C14.9816 11.8003 15.4276 12.3061 15.7528 12.962C16.078 13.6178 16.2405 14.396 16.2405 15.2965ZM15.24 15.2965C15.24 14.5572 15.1164 13.9333 14.869 13.4247C14.6245 12.9161 14.2924 12.5312 13.8727 12.27C13.4558 12.0087 12.9889 11.8781 12.472 11.8781C11.9551 11.8781 11.4868 12.0087 11.0671 12.27C10.6503 12.5312 10.3182 12.9161 10.0708 13.4247C9.82626 13.9333 9.70397 14.5572 9.70397 15.2965C9.70397 16.0357 9.82626 16.6596 10.0708 17.1682C10.3182 17.6768 10.6503 18.0617 11.0671 18.3229C11.4868 18.5842 11.9551 18.7148 12.472 18.7148C12.9889 18.7148 13.4558 18.5842 13.8727 18.3229C14.2924 18.0617 14.6245 17.6768 14.869 17.1682C15.1164 16.6596 15.24 16.0357 15.24 15.2965Z'
      fill='black'
    />
    <path
      d='M22.6479 13.1621C22.5978 12.7396 22.395 12.4117 22.0392 12.1782C21.6835 11.9448 21.2472 11.8281 20.7303 11.8281C20.3523 11.8281 20.0216 11.8892 19.7381 12.0115C19.4574 12.1338 19.2378 12.3019 19.0794 12.5159C18.9238 12.7299 18.846 12.9731 18.846 13.2454C18.846 13.4733 18.9002 13.6693 19.0086 13.8332C19.1197 13.9944 19.2615 14.1292 19.4338 14.2376C19.6061 14.3432 19.7867 14.4307 19.9757 14.5002C20.1647 14.5669 20.3384 14.6211 20.4968 14.6628L21.3639 14.8963C21.5862 14.9546 21.8336 15.0352 22.1059 15.138C22.3811 15.2409 22.6437 15.3812 22.8938 15.5591C23.1467 15.7342 23.3552 15.9593 23.5191 16.2344C23.6831 16.5096 23.7651 16.8472 23.7651 17.2474C23.7651 17.7088 23.6442 18.1256 23.4024 18.498C23.1634 18.8704 22.8132 19.1664 22.3519 19.386C21.8933 19.6055 21.3361 19.7153 20.6802 19.7153C20.0688 19.7153 19.5394 19.6166 19.0919 19.4193C18.6473 19.222 18.2971 18.9469 18.0414 18.5939C17.7885 18.241 17.6454 17.831 17.612 17.3641H18.6792C18.707 17.6865 18.8154 17.9533 19.0044 18.1645C19.1962 18.373 19.4379 18.5286 19.7298 18.6314C20.0243 18.7315 20.3412 18.7815 20.6802 18.7815C21.0749 18.7815 21.4292 18.7176 21.7433 18.5897C22.0573 18.4591 22.306 18.2785 22.4895 18.0478C22.6729 17.8144 22.7646 17.542 22.7646 17.2307C22.7646 16.9473 22.6854 16.7166 22.527 16.5387C22.3686 16.3609 22.1601 16.2164 21.9017 16.1052C21.6432 15.994 21.3639 15.8967 21.0638 15.8134L20.0132 15.5132C19.3462 15.3215 18.8182 15.0477 18.4291 14.692C18.04 14.3363 17.8455 13.8707 17.8455 13.2955C17.8455 12.8175 17.9747 12.4006 18.2332 12.0448C18.4944 11.6863 18.8446 11.4084 19.2837 11.2111C19.7256 11.011 20.2189 10.911 20.7636 10.911C21.3139 10.911 21.803 11.0096 22.231 11.2069C22.659 11.4015 22.998 11.6683 23.2482 12.0073C23.5011 12.3464 23.6345 12.7313 23.6484 13.1621H22.6479Z'
      fill='black'
    />
  </svg>
);
