// eslint-disable-next-line
function MemoryStorage() {}

MemoryStorage.prototype.getItem = function getItem(key) {
  return key in this ? this[key] : null;
};

MemoryStorage.prototype.setItem = function setItem(key, value) {
  this[key] = value;
};

MemoryStorage.prototype.removeItem = function removeItem(key) {
  if (key in this) {
    delete this[key];
  }
};

MemoryStorage.prototype.clear = function clear() {
  Object.keys(this).forEach(key => {
    delete this[key];
  });
};

export default new MemoryStorage();
