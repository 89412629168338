const RegexValidations = {
  url: (value: string) =>
    /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,10}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g.test(
      value,
    ),
  email: (value: string) => {
    if (value === '') return false;

    return /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,63}$/.test(value);
  },
  domain: (value: string) => {
    if (value === '') return false;

    return /^(https?:\/\/)?(www\.)?([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/.test(
      value,
    );
  },
  // This validation is quite permissive and doesn't check for a maximum length or specific country code formats. It only ensures that the string starts with '+' and is followed by one or more digits. TODO: Add more specific validation with refactor and improvements.
  phoneNumber: (value: string) => {
    if (value === '') return false;

    return /^\+[0-9]{1,}$/.test(value);
  },
};

export default RegexValidations;
