import { MerchantTask } from '../constants';
import { get, post } from './axios';

interface SignRequestParams {
  filename: string;
  filetype: string;
}
export const signS3 = async ({ filename, filetype }: SignRequestParams) => {
  const encodedFilename = encodeURIComponent(filename);

  const {
    data: { data: s3data, url },
    error,
  } = await get(`/sign-s3/?filename=${encodedFilename}&filetype=${filetype}`);

  return {
    data: {
      s3data,
      url,
    },
    error,
  };
};

export const fetchBannersAPI = () => get('/dashboard/banner/v2/');

export const createMerchantTaskAPI = (payload: { task: MerchantTask }) =>
  post('/dashboard/merchant-tasks/', payload);
