/**
 * TODO rewrite the axios API innterface to accept generic types from the caller
 * eg: get<T>(url) should return data of type
 * {
 *  data: T,
 *  error: string,
 *  status: number;
 * }
 */
import Axios, { AxiosError, Method } from 'axios';
import retry from 'axios-retry';
import store from 'src/store';
import { EnhancedAxiosInstance } from './types';
import {
  axiosInterceptorPlatformHandler,
  axiosInterceptRequestHandler,
  axiosInterceptResponseErrorHandler,
  isRetryable,
} from './utils';

const axios: EnhancedAxiosInstance = Axios.create({});
type APIVersion = 'v1' | 'v2' | 'v3';

axios.interceptors.request.use(axiosInterceptorPlatformHandler);
axios.interceptors.request.use(axiosInterceptRequestHandler);
axios.interceptors.response.use(res => res, axiosInterceptResponseErrorHandler);
retry(axios, { retryCondition: isRetryable });

export const getUser = () => {
  if (store) {
    return store.getState().user.user;
  }
  return {
    subdomain: '',
    token: '',
    website: {
      subdomain: '',
    },
  };
};

const getBaseURL = (version: APIVersion = 'v1') => {
  const user = getUser();
  if (user) {
    return `${process.env.NEXT_PUBLIC_API_ENDPOINT}/api/${version}/${user?.website?.subdomain}`;
  }

  return ``;
};

// export const getUser = () => {};

/**
 * This map will keep a record of the requests being made by the helpers so that we
 * can warn the user if they attempt to close the app when an operation is in progress
 */
export const actionRequestTracker = new Map<string, true>();

/**
 * Overloaded methods for makeAxiosRequest
 *
 * GET & DELETE do not need data, while it's
 * required for the others.
 */
function makeAxiosRequest(method: 'GET' | 'DELETE'): (
  url: string,
  data?: null,
  config?: {
    baseURL?: string;
    dataFallback?: any;
    version?: APIVersion;
    withCredentails?: boolean;
  },
) => Promise<{ data; error: string; status: string | number }>;

function makeAxiosRequest(method: 'POST' | 'PUT' | 'PATCH'): (
  url: string,
  data: AnyObject,
  config?: {
    baseURL?: string;
    dataFallback?: any;
    version?: APIVersion;
    withCredentails?: boolean;
  },
) => Promise<{
  data;
  error:
    | string
    | {
        error_type: string;
        message: string;
      };
  status: string | number;
}>;

function makeAxiosRequest(method: Method) {
  return (url: string, data, config) => {
    if (method !== 'GET') actionRequestTracker.set(url, true);

    return axios({
      url,
      method,
      baseURL: config?.baseURL || getBaseURL(config?.version),
      data,
      withCredentials: config?.withCredentails || false,
    })
      .then(
        ({ data: { result, error, status, errors }, status: httpStatus }) => ({
          data: result || config?.dataFallback,
          error: error || errors?.[0],
          status: status || httpStatus,
        }),
      )
      .catch((error: AxiosError) => ({
        data: config?.dataFallback || {},
        status: error.response?.status,
        error:
          error.response?.data?.error ||
          error.response?.data?.errors?.[0] ||
          error.message,
      }))
      .finally(() => {
        actionRequestTracker.delete(url);
      });
  };
}

const get = makeAxiosRequest('GET');
const post = makeAxiosRequest('POST');
const put = makeAxiosRequest('PUT');
const patch = makeAxiosRequest('PATCH');
const del = makeAxiosRequest('DELETE');

export { get, post, put, del, patch };

export type { APIResponseObject, AxiosResponse } from './types';

// Updating the base URL of the default axios instance.
// When this function is called it will give an instance with correnct base URL
export const getAxios = (version: APIVersion = 'v1') => {
  axios.defaults.baseURL = getBaseURL(version);
  return axios;
};

export default axios;
