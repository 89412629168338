import { createModel } from '@rematch/core';
import { RootModel } from '.';

export const TOAST_TTL = 5000;

export type ToastType = 'error' | 'success';

interface SaveToastState {
  hidden: boolean;
  isLoading: boolean;
  message: string | null;
  timeOfRender: Date | null;
  type: ToastType;
}

const initialState = (): SaveToastState => ({
  hidden: true,
  isLoading: false,
  message: null,
  timeOfRender: null,
  type: 'success',
});

const saveToast = createModel<RootModel>()({
  state: initialState(),

  effects: {
    async showProgress(payload?: string) {
      this.show({
        hidden: false,
        isLoading: true,
        message: payload || 'Saving Changes',
      });
    },

    async showDone(payload: string) {
      const timeOfRender = new Date();

      this.show({
        timeOfRender,
        hidden: false,
        isLoading: false,
        type: 'success',
        message: payload || 'Changes saved',
      });

      setTimeout(() => {
        this.hide();
      }, TOAST_TTL);
    },

    async showError(payload: string) {
      const timeOfRender = new Date();

      this.show({
        timeOfRender,
        hidden: false,
        isLoading: false,
        type: 'error',
        message: payload || 'Unexpected error occurred',
      });

      setTimeout(() => {
        this.hide();
      }, TOAST_TTL);
    },
  },

  reducers: {
    hide(state: SaveToastState): SaveToastState {
      return {
        ...state,
        ...initialState(),
      };
    },

    show(state: SaveToastState, payload: any): SaveToastState {
      return {
        ...state,
        ...payload,
      };
    },
  },
});

export default saveToast;
