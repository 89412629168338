interface Props {
  color?: any;
  style?: any;
  size?: number;
}

export const Apple = ({ color = 'currentColor', style, size = 25 }: Props) => (
  <svg width={size} height={size} viewBox='4 4 13 13' fill='none' style={style}>
    <path
      d='M13.2176 9.9752C13.2342 11.7912 14.7829 12.3955 14.8 12.4032C14.7869 12.4458 14.5526 13.2644 13.9841 14.11C13.4927 14.841 12.9827 15.5693 12.1793 15.5844C11.3898 15.5992 11.136 15.1079 10.2334 15.1079C9.33116 15.1079 9.04912 15.5693 8.30183 15.5992C7.52633 15.6291 6.93579 14.8087 6.4403 14.0804C5.42784 12.5905 4.6541 9.87044 5.69303 8.03434C6.20915 7.12253 7.13149 6.54513 8.13261 6.53032C8.89413 6.51554 9.61292 7.05178 10.0785 7.05178C10.5437 7.05178 11.4172 6.4069 12.3354 6.50161C12.7198 6.51789 13.7989 6.65966 14.4918 7.69194C14.436 7.72716 13.2042 8.45697 13.2176 9.9752V9.9752ZM11.734 5.51596C12.1457 5.00871 12.4228 4.30257 12.3472 3.59998C11.7537 3.62425 11.0361 4.00249 10.6104 4.50945C10.229 4.9584 9.89485 5.67696 9.985 6.36565C10.6465 6.41774 11.3222 6.02353 11.734 5.51596'
      fill={color}
    />
  </svg>
);
