import {
  Badge,
  BlockStack,
  Box,
  Button,
  Icon,
  InlineStack,
  Text,
  Tooltip,
} from '@shopify/polaris';
import { ExternalSmallIcon, InfoIcon, MagicIcon } from '@shopify/polaris-icons';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { usePostHog } from 'posthog-js/react';
import { useState } from 'react';
import { useGlobalState } from 'src/hooks/use-global-state';
import { IntercomTriggerMessage } from 'src/lib/constants';
import { startConversationWithUser } from 'src/lib/utils';
import { CheckIcon } from './CheckIcon';
import { CrossIcon } from './CrossIcon';
import { Impressions } from './Impressions';
import {
  InverseButton,
  Modal,
  ModalContent,
  ModalOverlay,
  PricingContainer,
  PricingTable,
  SectionHeader,
  TableCell,
  TableHeader,
} from './styled';

const FeatureStatus = ({ status }) => {
  switch (status) {
    case 'disabled':
      return <CheckIcon color='#ccc' />;
    case 'enabled':
      return <CheckIcon />;
    default:
      return <CrossIcon />;
  }
};

export const TrialBaseModal = ({
  features,
  heading,
  subheading,
  webpushImpressions,
  emailImpressions,
  handleClose,
  paidPlanName,
  sku,
  pricingSlabs,
  amount,
}) => {
  const posthog = usePostHog();
  const { t } = useTranslation('trial');
  const router = useRouter();
  const { actions } = useGlobalState('pricingV3');
  const [isLoading, setIsLoading] = useState(false);

  const startTrial = async () => {
    setIsLoading(true);
    posthog.capture('integrated onboarding trial start cta clicked', {
      sku,
    });

    actions.changePricingPlan({
      category: 'omni',
      selectedSku: pricingSlabs,
      utmSource: 'dashboard',
      topLevelBillingSku: sku,
      utmMedium: 'integrated_onboarding_modal',
    });
  };

  return (
    <>
      <ModalOverlay />
      <BlockStack>
        <Modal>
          <BlockStack gap='200'>
            <ModalContent>
              <InlineStack gap='400' align='space-between' wrap={false}>
                <PricingContainer>
                  <PricingTable>
                    <thead>
                      <tr>
                        <TableHeader>{t('benefits')}</TableHeader>
                        <TableHeader>Basic Bundle</TableHeader>
                        <TableHeader>{paidPlanName}</TableHeader>
                      </tr>
                    </thead>
                    <tbody>
                      {features.map(row => {
                        switch (row.type) {
                          case 'header':
                            return (
                              <tr>
                                <SectionHeader
                                  colSpan={3}
                                  style={{
                                    paddingTop: '12px',
                                    paddingBottom: '0',
                                  }}
                                >
                                  {t(row.label)}
                                </SectionHeader>
                              </tr>
                            );
                          case 'label':
                            return (
                              <tr>
                                <TableCell>
                                  <InlineStack
                                    blockAlign='center'
                                    gap='200'
                                    wrap
                                  >
                                    <Text as='span'>{t(row.label)}</Text>

                                    {row.tooltip ? (
                                      <Tooltip
                                        zIndexOverride={999}
                                        content={t(row.tooltip)}
                                      >
                                        <div
                                          style={{
                                            position: 'relative',
                                            top: '4px',
                                          }}
                                        >
                                          <InfoIcon width={15} height={15} />
                                        </div>
                                      </Tooltip>
                                    ) : null}
                                  </InlineStack>
                                </TableCell>
                                <TableCell>
                                  <Text as='span' tone='subdued'>
                                    {row.free}
                                  </Text>
                                </TableCell>
                                <TableCell>
                                  <Text as='span'>{row.paidPlan}</Text>
                                </TableCell>
                              </tr>
                            );
                          default:
                            return (
                              <tr>
                                <TableCell>
                                  <InlineStack blockAlign='center' gap='200'>
                                    <Text as='span'>{t(row.label)}</Text>
                                    {row.tooltip ? (
                                      <Tooltip
                                        zIndexOverride={999}
                                        content={t(row.tooltip)}
                                      >
                                        <div
                                          style={{
                                            position: 'relative',
                                            top: '4px',
                                          }}
                                        >
                                          <InfoIcon width={15} height={15} />
                                        </div>
                                      </Tooltip>
                                    ) : null}
                                  </InlineStack>
                                </TableCell>
                                <TableCell>
                                  <FeatureStatus status={row.free} />
                                </TableCell>
                                <TableCell>
                                  <FeatureStatus status={row.paidPlan} />
                                </TableCell>
                              </tr>
                            );
                        }
                      })}
                    </tbody>
                  </PricingTable>
                </PricingContainer>

                <Box minWidth='300px'>
                  <BlockStack gap='1200'>
                    <BlockStack gap='300'>
                      <div>
                        <Badge icon={MagicIcon} tone='success'>
                          {t('badge')}
                        </Badge>
                      </div>
                      <Box maxWidth='35ch'>
                        <BlockStack gap='200'>
                          <Text
                            as='h2'
                            variant='headingXl'
                            fontWeight='regular'
                          >
                            {heading}
                          </Text>

                          <BlockStack
                            gap='200'
                            align='start'
                            inlineAlign='start'
                          >
                            <Text as='p' variant='bodyLg' tone='subdued'>
                              {subheading}
                            </Text>
                            <div id='view-all-features'>
                              <InlineStack gap='025' align='start'>
                                <Icon source={ExternalSmallIcon} tone='info' />

                                <Button
                                  variant='plain'
                                  onClick={() =>
                                    router.push(
                                      '/pricing?utm_source=trial_modal',
                                    )
                                  }
                                >
                                  {t('view_all_features', {
                                    planName: paidPlanName,
                                  })}
                                </Button>
                              </InlineStack>
                            </div>
                          </BlockStack>
                        </BlockStack>
                      </Box>
                      <BlockStack gap='400'>
                        <Box
                          background='bg-fill-active'
                          padding='300'
                          borderRadius='200'
                        >
                          <BlockStack gap='200'>
                            <Text as='h3' variant='headingMd'>
                              {t('channels')}
                            </Text>

                            <BlockStack gap='100'>
                              <Impressions
                                label='webpush'
                                tooltip='webpush_tooltip'
                                impressions={webpushImpressions}
                                amount={amount}
                              />
                              <Impressions
                                label='email'
                                impressions={emailImpressions}
                                amount={0}
                              />
                            </BlockStack>

                            <div
                              style={{
                                borderStyle: 'dashed',
                                borderWidth: '1px',
                                borderColor: 'var(--p-color-border)',
                              }}
                            />
                            <BlockStack gap='025'>
                              <Text as='p' variant='bodySm' tone='subdued'>
                                {t('total')}
                              </Text>
                              <InlineStack gap='100' align='start'>
                                <Text as='span'>{t('free_for_14_days')}</Text>
                                <div
                                  style={{
                                    position: 'relative',
                                  }}
                                >
                                  <div>${amount}</div>
                                  <div
                                    style={{
                                      position: 'absolute',
                                      top: '50%',
                                      left: '-5%',
                                      right: '-5%',
                                      height: '3px',
                                      backgroundColor:
                                        'var(--p-color-bg-fill-caution)',
                                      transform:
                                        'translateY(-50%) rotate(-5deg)',
                                    }}
                                  />
                                </div>
                              </InlineStack>
                            </BlockStack>
                          </BlockStack>
                        </Box>
                      </BlockStack>
                    </BlockStack>

                    <div>
                      <BlockStack gap='200'>
                        <Button
                          variant='plain'
                          onClick={() => {
                            posthog.capture(
                              'integrated onboarding trial skipped',
                              {
                                sku,
                              },
                            );
                            handleClose();
                          }}
                          id='skip-trial'
                          size='large'
                        >
                          {t('secondary_cta')}
                        </Button>
                        <Button
                          tone='success'
                          variant='primary'
                          size='large'
                          id='start-trial'
                          onClick={startTrial}
                          loading={isLoading}
                        >
                          {t('primary_cta')}
                        </Button>
                      </BlockStack>
                    </div>
                  </BlockStack>
                </Box>
              </InlineStack>
            </ModalContent>
            <InlineStack gap='200' align='center'>
              <InverseButton
                type='button'
                onClick={() => router.push('/pricing?utm_source=trial_modal')}
              >
                {t('compare_plans')}
              </InverseButton>
              <Text as='span' tone='text-inverse'>
                •
              </Text>
              <InverseButton
                type='button'
                onClick={() =>
                  startConversationWithUser(IntercomTriggerMessage.TRIAL)
                }
              >
                {t('chat_with_us')}
              </InverseButton>
            </InlineStack>
          </BlockStack>
        </Modal>
      </BlockStack>
    </>
  );
};
