import { LinkLikeComponentProps } from '@shopify/polaris/build/ts/src/utilities/link';
import Link from 'next/link';

/**
 * wraps all the polaris button(with url) and links in a next link
 */
export const PolarisLinkWrapper = (props: LinkLikeComponentProps) => {
  const { children, url, ...rest } = props;

  return (
    <Link href={url} passHref legacyBehavior>
      <a {...rest}>{children}</a>
    </Link>
  );
};
