import { withScope, captureException } from '@sentry/browser';

interface SentryLoggerArgs {
  error: Error | string;
  extras?: AnyObject;
}

export const logErrorToSentry = ({ error, extras }: SentryLoggerArgs) => {
  withScope(scope => {
    scope.setExtras({
      ...extras,
      subdomain: typeof window !== 'undefined' ? window._po_subdomain : null,
    });

    captureException(error);
  });
};

interface DebugLogParams {
  message: string;
  data?: AnyObject;
}

/**
 * Log to our log sink for debugging purposes
 * @param payload DebugLogParams Log data.
 */
export const debugLog = (payload: DebugLogParams) => {
  fetch(`${process.env.NEXT_PUBLIC_API_ENDPOINT}/logs/v1/`, {
    method: 'POST',
    headers: {
      'Accept-Type': 'text/plain',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify([
      {
        level: 'warn',
        subdomain: window._po_subdomain,
        message: payload.message,
        timestamp: new Date().toISOString(),
        data: {
          url: window.location.href,
          /* eslint-disable-next-line dot-notation */
          connectionType: window.navigator['connection']?.effectiveType,
          buildId: window._po_build,
          ...payload.data,
        },
      },
    ]),
  });
};
