import { pxToRem } from './utils';

export const fontSizes = [
  pxToRem(12),
  pxToRem(14),
  pxToRem(16),
  pxToRem(18),
  pxToRem(20),
  pxToRem(24),
  pxToRem(28),
  pxToRem(36),
];
