import { pxToRem } from './utils';

const margin = [
  pxToRem(0),
  pxToRem(2),
  pxToRem(4),
  pxToRem(8),
  pxToRem(12),
  pxToRem(16),
  pxToRem(20),
  pxToRem(24),
  pxToRem(28),
  pxToRem(32),
  pxToRem(36),
  pxToRem(40),
  pxToRem(46),
  pxToRem(52),
  pxToRem(58),
  pxToRem(64),
];

// FIXME: Remove this. Spacing is spacing. We don't need to differentiate between padding and margin here.
const padding = [...margin];

const space = {
  margin,
  padding,
};

export default space;
