import { createModel } from '@rematch/core';
import {
  fetchAllIntegrationsAPI,
  saveIntegrationConfigAPI,
} from 'src/lib/api/integrations';
import { RootModel } from 'src/store/models';

interface IntegrationsState {
  isFetching: boolean;
  integrations: Array<AnyObject>;
}

const initialState = (): IntegrationsState => ({
  isFetching: true,
  integrations: null,
});

const integrationsModel = createModel<RootModel>()({
  state: initialState(),

  effects: dispatch => ({
    async fetch() {
      const res = await fetchAllIntegrationsAPI();
      if (!res.error) {
        this.storeIntegrations(res.data);
      }
    },

    async updateStatus(
      { handle, status }: { handle: string; status: 'enabled' | 'disabled' },
      rootState,
    ) {
      const integration = rootState.integrations.integrations.find(
        i => i.handle === handle,
      );

      const data = { config: { ...integration.config, status } };
      this.storeIntegration({ handle, data });

      const res = await saveIntegrationConfigAPI({
        handle,
        config: data.config,
      });

      if (!res.error) {
        dispatch.saveToast.showDone('Changes saved');
      } else {
        dispatch.saveToast.showError(`Error updating status`);
        data.config.status = status === 'enabled' ? 'disabled' : 'enabled';
        this.storeIntegration({ handle, data });
      }
    },
  }),

  reducers: {
    storeIntegrations(state: IntegrationsState, payload) {
      return {
        ...state,
        ...payload,
        isFetching: false,
      };
    },

    storeIntegration(state: IntegrationsState, payload) {
      const newIntegrations = [...state.integrations];
      const index = newIntegrations.findIndex(i => i.handle === payload.handle);
      newIntegrations[index] = { ...newIntegrations[index], ...payload.data };

      return {
        ...state,
        integrations: newIntegrations,
      };
    },
  },
});

export default integrationsModel;
