import { BlockStack, InlineStack, Text, Tooltip } from '@shopify/polaris';
import { InfoIcon } from '@shopify/polaris-icons';
import useTranslation from 'next-translate/useTranslation';

type Props = {
  impressions: number;
  label: string;
  tooltip?: string;
  amount: number;
};

export const Impressions = ({ impressions, label, tooltip, amount }: Props) => {
  const { t } = useTranslation('trial');

  const formattedImpression = impressions.toLocaleString();

  return (
    <BlockStack gap='025'>
      <InlineStack gap='100' align='start'>
        <Text as='p' variant='bodySm' tone='subdued'>
          {t(label)}
        </Text>

        {tooltip && (
          <Tooltip zIndexOverride={999} content={t(tooltip)}>
            <div>
              <InfoIcon width={15} height={15} />
            </div>
          </Tooltip>
        )}
      </InlineStack>

      <InlineStack>
        <Text as='span'>${amount}</Text>
        <Text as='span'>
          {t('per_month', { impressions: formattedImpression })}
        </Text>
      </InlineStack>
    </BlockStack>
  );
};
