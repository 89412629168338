import { getAxios, AxiosResponse } from './axios';

export interface FeedParams {
  startTime: Date | null;
  endTime: Date | null;
  granularity: string;
  names: string;
  records_per_page: number;
  page: number;
}

export const fetchActivityFeedAPI: (args: FeedParams) => AxiosResponse = ({
  startTime,
  endTime,
  granularity,
  names,
  records_per_page,
  page,
}) => {
  const axios = getAxios();
  return axios
    .get(
      `/dashboard/changelog/?granularity=${granularity}&names${names}&start_time=${
        startTime ? startTime.toISOString() : ''
      }&end_time=${
        endTime ? endTime.toISOString() : ''
      }&records_per_page=${records_per_page}&page=${page}`,
    )
    .then(res => ({ data: res.data, error: null }))
    .catch(error => ({
      error,
      data: {},
    }));
};
