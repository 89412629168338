import { createModel } from '@rematch/core';

export enum ExitType {
  DEFAULT = 'default',
  SCALE_DOWN = 'scaleDown',
}

export enum InitialType {
  DEFAULT = 'default',
  SCALE_UP = 'scaleUp',
  SCALE_DOWN = 'scaleDown',
  SCALE_DOWN_MOBILE = 'scaleDownMobile',
}

export interface Circle {
  x: number;
  y: number;
  transition: boolean;
  width: number;
  height: number;
  color: string;

  // flip only applied to circle1 ~ pink circle
  flip?: boolean;
}

export interface State {
  // Step needs to be a string because it's used as an object property in onboarding/views/index
  step: string;
  logoPosition: 'center' | 'left';

  // pink
  circle1: Circle;

  // yellow
  circle2: Circle;

  // blue
  circle3: Circle;

  // Used in NotificationTypes for initial and exit animations
  shouldStep5FadeOut: boolean;
  shouldStep5FadeIn: boolean;

  // Used in CartReminder for initial and exit animations
  step6ExitType: ExitType;
  step6InitialType: InitialType;

  // Motion preference
  isReducedMotionPreferred: boolean;
}

const checkForPrefersReducedMotion = () =>
  typeof window === 'undefined'
    ? false
    : window.matchMedia('(prefers-reduced-motion: reduce)').matches;

const initialState = (): State => ({
  step: '1',
  logoPosition: checkForPrefersReducedMotion() ? 'left' : 'center',
  circle1: {
    x: 0,
    y: 0,
    transition: false,
    flip: false,
    color: '#fd3363',
    width: 110,
    height: 110,
  },
  circle2: {
    x: 0,
    y: 0,
    transition: false,
    color: '#ecc94b',
    width: 336,
    height: 336,
  },
  circle3: {
    x: 0,
    y: 0,
    transition: false,
    color: '#1053ff',
    width: 336,
    height: 336,
  },
  shouldStep5FadeOut: true,
  shouldStep5FadeIn: true,
  step6ExitType: ExitType.DEFAULT,
  step6InitialType: InitialType.DEFAULT,
  isReducedMotionPreferred: checkForPrefersReducedMotion(),
});

const onboarding = createModel()({
  state: initialState(),

  reducers: {
    setProp(state, payload: Partial<State>) {
      return {
        ...state,
        ...payload,
      };
    },
  },
});

export default onboarding;
