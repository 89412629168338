import { useDispatch, useSelector } from 'react-redux';
import { StoreState, Dispatch } from 'src/store';
import models from 'src/store/models';

export const useGlobalState = <T extends keyof typeof models>(key: T) => {
  const dispatch: Dispatch = useDispatch();
  const actions = dispatch[key];
  const state = useSelector((storeState: StoreState) => storeState[key]);

  return {
    actions,
    state,
  };
};
