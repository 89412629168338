import { ChargeType } from 'src/modules/pricing-v2/models/types';
import { get, post } from './axios';

export const fetchPricingAPI = () => get(`/dashboard/pricing/`);

export const fetchPricingV2API = () =>
  get(`/dashboard/pricing/`, null, {
    version: 'v2',
  });

export const fetchPricingV3API = () =>
  get(`/dashboard/pricing/`, null, {
    version: 'v3',
  });

export type PlanChange = {
  billing_plan_sku: string;
  coupon_code?: string;
  utm_source?: string;
  utm_medium?: string;
  pricing_slab_skus?: string[];
};

export type EmailPlanChange = {
  email_pricing_slab_sku: string;
  charge_type: ChargeType;
};

type PlanCancel = {
  billing_plan_sku: string;
  pricing_slab_skus: string[];
};

export const changePlanAPI = (data: PlanChange) =>
  post(`/dashboard/billing/init/`, data);

export const changeEmailPlanAPI = (data: EmailPlanChange) =>
  post(`/dashboard/billing/init/email/`, data);

export const cancelPlanAPI = async (data: PlanCancel | {} = {}) =>
  post(`/dashboard/billing/cancel/`, data);

export const validateCouponAPI = async ({ code }: { code: string }) =>
  get(`/dashboard/pricing/coupons/?code=${code}`);
