export enum ReservedContainerNodeId {
  FORM_FIELDS = 'form_fields',
  TEXT_CONTENT = 'text_content',
  IMAGE = 'image',
}

export enum ReservedElementNodeId {
  EMAIL = 'email',
  PHONE = 'phone',
  SUBMIT = 'submit',
  TITLE = 'title',
  SUBTITLE = 'subtitle',
  TEASER = 'flyout',
  CONSENT_EMAIL_COPY = 'consent-email-copy',
  CONSENT_SMS_COPY = 'consent-sms-copy',
  CONSENT_EMAIL_CHECKBOX = 'consent-email-checkbox',
  CONSENT_SMS_CHECKBOX = 'consent-sms-checkbox',
  CUSTOM_FIELD = 'custom_field',
}

export const ReservedContainerNodeIds: string[] = Object.values(
  ReservedContainerNodeId,
);

export const ReservedElementNodeIds: string[] = Object.values(
  ReservedElementNodeId,
);

export const ReservedContainerDisplayName: Record<
  ReservedContainerNodeId,
  string
> = {
  [ReservedContainerNodeId.FORM_FIELDS]: 'Form',
  [ReservedContainerNodeId.TEXT_CONTENT]: 'Content',
  [ReservedContainerNodeId.IMAGE]: 'Image',
};

export const ReservedElementDisplayName: Record<ReservedElementNodeId, string> =
  {
    [ReservedElementNodeId.EMAIL]: 'Email address',
    [ReservedElementNodeId.SUBMIT]: 'Subscribe button',
    [ReservedElementNodeId.PHONE]: 'Phone number',
    [ReservedElementNodeId.TITLE]: 'Title',
    [ReservedElementNodeId.SUBTITLE]: 'Byline',
    [ReservedElementNodeId.TEASER]: 'Teaser',
    [ReservedElementNodeId.CONSENT_EMAIL_CHECKBOX]: 'Email Checkbox',
    [ReservedElementNodeId.CONSENT_SMS_CHECKBOX]: 'SMS Checkbox',
    [ReservedElementNodeId.CONSENT_EMAIL_COPY]: 'Email Consent',
    [ReservedElementNodeId.CONSENT_SMS_COPY]: 'TCPA compliance',
    [ReservedElementNodeId.CUSTOM_FIELD]: 'Custom Field',
  };

export const PlaceHolderInput = {
  [ReservedElementNodeId.EMAIL]: 'Email Placeholder',
  [ReservedElementNodeId.PHONE]: 'Phone Placeholder',
};

export const PlaceHolder = {
  [ReservedElementNodeId.EMAIL]: 'Enter your email address',
  [ReservedElementNodeId.PHONE]: '+19123456789',
};

export const ConsentCopy: {
  sms: string;
  email: string;
} = {
  sms: 'By checking this box, I consent to receive marketing text messages through an automatic telephone dialing system at the number provided. Consent is not a condition to purchase. Text STOP to unsubscribe or HELP for help. Msg and data rates may apply',
  email:
    'By checking this box, I consent to receive marketing emails at the email address provided. Consent is not a condition to purchase.',
};

export const DefaultDateFormat = 'MM/DD/YYYY';

export const DISABLED_TIMEOUT = -1;

export const TIMEOUTS = [
  DISABLED_TIMEOUT,
  0,
  3,
  5,
  10,
  15,
  20,
  25,
  30,
  35,
  40,
  45,
  50,
  55,
  60,
];
