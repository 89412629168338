import { finder } from '@medv/finder';
import { capitalize } from 'src/lib/utils';
import { v1 as uuidv1 } from 'uuid';
import {
  DefaultDateFormat,
  ReservedElementNodeId,
} from '../components/FormWidget/lib/constants';
import {
  BaseTreeNode,
  TreeNode,
  TreeNodeByType,
  TreeNodeType,
} from '../components/FormWidget/lib/types';
import { CustomAttribute, CustomElementType } from '../models/types';

/**
 * applies changes to a node in a tree in a mutable way, creates a new tree and returns it
 * although it seems like we mutate the node, the `applyChangesToNodeTree` creates a new node out of the mutated node.
 * This is directly inspired by how [immer](https://immerjs.github.io/immer/produce) returns new data after performing mutable operations inside a callback
 *
 * *API usage:*
 * ```
 * applyChangesToNodeTree(
 *   'nodeId',
 *   (node) => {
 *     node.attr.someAttr = someValue;
 *     node.children = someValue
 *   },
 *   tree
 *  );
 * ```
 */
export const applyChangesToNodeTree = (
  nodeId: string,
  callback: (node: TreeNode) => void,
  tree: TreeNode,
): TreeNode => {
  const cloneNode = (node: TreeNode): TreeNode => {
    if (node.id === nodeId) {
      callback(node);
    }
    if (
      node.children &&
      Array.isArray(node.children) &&
      // only box has children as of now
      node.type === TreeNodeType.BOX
    ) {
      return {
        ...node,
        children: node.children.map(child => cloneNode(child)),
      };
    }
    return { ...node };
  };
  return cloneNode(tree);
};

function isTreeNode(node: BaseTreeNode): node is TreeNode {
  return 'type' in node;
}

/**
 * searches for a node in a tree(`node`) by its id, goes to a maximum depth of 8,
 * if a tree is more than 8 levels deep, we might have a serious problem on the way we structure our tree
 */
export const getTreeNodeById = (
  node: TreeNode | TreeNode[],
  id: string,
  n = 8,
  doesIdsMatch?: (currentId, nodeIdToMatch) => boolean,
): TreeNode | null => {
  if (!Array.isArray(node)) {
    if (doesIdsMatch ? doesIdsMatch(node.id, id) : node.id === id) {
      return node;
    }

    if (n === 0 || !node.children) {
      return null; // If depth is 0 or node has no children, stop searching
    }

    const children = Array.isArray(node.children)
      ? node.children
      : [node.children];
    // enabling for loops
    // eslint-disable-next-line no-restricted-syntax
    for (const child of children) {
      if (typeof child !== 'string' && isTreeNode(child)) {
        const foundNode = getTreeNodeById(child, id, n - 1, doesIdsMatch);
        if (foundNode) {
          return foundNode;
        }
      }
    }
  }

  return null;
};

export const createCustomFieldTreeNode = (
  attribute: CustomAttribute,
): TreeNodeByType<TreeNodeType.INPUT | TreeNodeType.DATE> => {
  const smallUUID = uuidv1().split('-')[0];
  switch (attribute.type) {
    case 'float':
    case 'text':
      return {
        id: `${ReservedElementNodeId.CUSTOM_FIELD}-${smallUUID}`,
        type: TreeNodeType.INPUT,
        attr: {
          color: '#000',
          placeholder: `Enter your ${attribute.name}`,
          fieldType: attribute.type === 'float' ? 'number' : 'text',
          mapAttribute: attribute.name,
          required: true,
        },
        children: capitalize(attribute.name.toLowerCase()).replace('_', ' '),
      };
    case 'date':
      return {
        id: `${ReservedElementNodeId.CUSTOM_FIELD}-${smallUUID}`,
        type: TreeNodeType.DATE,
        attr: {
          color: '#000',
          placeholder: DefaultDateFormat,
          fieldType: attribute.type,
          mapAttribute: attribute.name,
          dateFormat: DefaultDateFormat,
          required: true,
        },
        children: capitalize(attribute.name.toLowerCase()).replace('_', ' '),
      };
    default:
      return null;
  }
};

export const parseCustomOptinHTML = (html: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  const body = doc?.body;

  const textOnlyInputs: HTMLElement[] = Array.from(
    body?.querySelectorAll(
      'input:not([type=radio]), input:not([type=checkbox])',
    ),
  );

  const buttons: HTMLElement[] = Array.from(body?.querySelectorAll('button'));

  return {
    textOnlyInputs,
    buttons,
  };
};

export const getCustomMappableActions = (
  mappedActions: TreeNodeByType<TreeNodeType.HTML>['attr'],
): Record<
  CustomElementType,
  Array<{ value: string; label: string; disabled?: boolean }>
> => {
  const allActions: ReturnType<typeof getCustomMappableActions> = {
    button: [
      {
        value: 'submit',
        label: 'Submit opt-in',
      },
      {
        value: 'dismiss',
        label: 'Dismiss opt-in',
      },
    ],
    input: [
      {
        value: 'email',
        label: 'Email field',
      },
      {
        value: 'phone',
        label: 'Phone number field',
      },
    ],
  };

  return allActions;
};

export const isCustomElementValid = (
  element: HTMLElement,
  mappedActions: TreeNodeByType<TreeNodeType.HTML>['attr'],
  type: CustomElementType,
  DOMTree: Element,
) => {
  try {
    const selector = finder(element, { root: DOMTree });
    if (!selector) return false;

    const mappedActionsForType = mappedActions[type];

    if (!mappedActionsForType) return false;

    let isValid = false;

    Object.entries(mappedActionsForType).forEach(([_, actionValue]) => {
      Object.keys(actionValue).forEach(el => {
        if (el === selector) {
          isValid = true;
        }
      });
    });

    return isValid;
  } catch {
    return false;
  }
};

/**
 * returns the position of the image node in the root box node
 *
 * this function is a bit opinionated and assumes that image node exists as a child of root node
 * this might subject to change in the future
 */
export const getImageNodePosition = (imageNode: TreeNode, tree: TreeNode) => {
  const rootChildren = tree.children as TreeNode[];

  if (tree.type === TreeNodeType.BOX) {
    if (tree.attr.dir === 'LR') {
      const imageNodeIndex = rootChildren.findIndex(
        child => child.id === imageNode.id,
      );

      if (imageNodeIndex === 0) {
        return 'left';
      }

      if (imageNodeIndex === 1) {
        return 'right';
      }
    } else if (tree.attr.dir === 'TB') {
      const imageNodeIndex = rootChildren.findIndex(
        child => child.id === imageNode.id,
      );

      if (imageNodeIndex === 0) {
        return 'top';
      }
    }
  }

  return null;
};
