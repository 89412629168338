import { createModel } from '@rematch/core';
import { brevoProxyClearCookie, brevoProxyLogin } from 'src/lib/api/brevo';
import { logErrorToSentry } from 'src/lib/debug-utils';
import { RootModel } from 'src/store/models';

type InitialState = {
  isProxyAuthenticating: boolean;
};

const initialState: InitialState = {
  isProxyAuthenticating: true,
};

const brevomfe = createModel<RootModel>()({
  state: initialState,
  effects: dispatch => ({
    async brevoProxyLogin(): Promise<boolean> {
      dispatch.brevomfe.setIsProxyAuthenticating(true);

      try {
        /**
         * it might be weird to clear cookie/logout before login,
         * this is not actual logout, this is to pervent a cookie clash between staging and prod
         * backend calls it "logout API" because it just clears cookies
         *
         * assume if you open staging and prod in the same browser, with different logins
         * the prod cookie has higher precedence than staging (since prod cookies is signed with pushowl.io domain)
         * the staging wound then start sending prod cookie instead of its own cookie, which is very bad
         * you might not know are using a prod brevo account in staging
         *
         * clearing cookie prevents this clash, downside of this is that,
         * you cannot have both staging and prod brevo MFEs open in the same browser
         * one of them will be logged out
         */
        await brevoProxyClearCookie();
        const { error } = await brevoProxyLogin();

        if (!error) {
          dispatch.brevomfe.setIsProxyAuthenticating(false);
          return true;
        }
      } catch (error) {
        logErrorToSentry({
          error,
          extras: { message: 'brevo proxy login failed' },
        });
      }
      dispatch.brevomfe.setIsProxyAuthenticating(false);
      return false;
    },
  }),
  reducers: {
    setIsProxyAuthenticating(state, isProxyAuthenticating: boolean) {
      return {
        ...state,
        isProxyAuthenticating,
      };
    },
  },
});

export default brevomfe;
